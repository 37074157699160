.thumbnail-image {
    width: 100px;
    height: 100px;
    padding: 8px;
    overflow: hidden;
}

.thumbnail-image>img {
    object-fit: contain !important;

}

.eco-app-content {
    max-width: calc(100vw);
}

.anticon {
    display: inline-block !important;
    vertical-align: 0 !important;
}

/* DASHBOARD ICON   */
.plus_icon_dash {
    height: 17px !important;
    width: 17px !important;
    margin: 5px;
}

.eco-typography {
    /* margin-top: 6px !important; */
}

/* .ant-table-cell {
    color : #8181A5 !important
} */

.add__btn {
    background-color: rgba(94, 129, 244, 10%) !important;
    border: none !important;
    color: #5E81F4 !important;
    border-radius: 10px !important;
}

.add__btn:hover {
    color: #fff !important;
    background-color: #5E81F4 !important;
}

.btn__submit {
    color: #5E81F4 !important;
    background-color: rgba(94, 129, 244, 10%) !important;

    border: none !important;
    /* color: #fff !important; */
}

.btn__submit:hover {
    color: #fff !important;
    background-color: #5E81F4 !important;

}

.image-upload-section {
    border: 2px dashed #cecece;
    border-radius: 10px;
    min-height: 150px;
    padding: 10px;
}

/* Publish Unpublish Tag */
.status_btn {
    width: 106px !important;
    height: 38px !important;
    border-radius: 8px !important;
    justify-content: center !important;
}
.eco-main-side-panel__logo-wrapper a img{
    height: auto!important;
}
.product-page-content{
    padding: 0!important;
}
.product-page-header{
    padding: 20px;
}
.site-listing{
    padding: 20px;
    background-color: #F0F1F4;
}
.eco-header__location-switcher .eco-stack__item{
    max-width: 0 10px !important;
}
.add-new-site-btn .eco-icon{
    fill: #fff!important;
}
.eco-tag{
    /* display: flex; */
    justify-content: center;
    width: 100px;
}