.main-menu, .sub-menu{
    padding: 10px;
}
.main-menu:hover , .sub-menu:hover{
    cursor: pointer;
}
.main-menu-active{
    background-color: #039be5;
    color: #fff;
}
.sub-menu-active{
    background-color: #cbd7dd;
    color: #000;
}

.eco-theme-dashbord .eco-restaurant-menu-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 100%;
}