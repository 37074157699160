.pages__overlay {
    width: 352px;
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 162px;
    left: 65px;
    overflow-x: hidden;
    transition: all .6s ease-in;
    background-color: #ffffff;
    box-shadow: 0px 0px 0px #5a5a5a;
    border-top: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    z-index: 99991;
    overflow: scroll;

}

.preview-card {
    padding: 20px;
    border-radius: 4px;
    width: 100%;
    background: #F3F3F3;
    min-height: 150px;
}

.add-page-button {
    background-color: #70738F !important;
    border: none !important;
    border-radius: 36px !important;
    color: #ffffff !important;
    width: 150px !important;
    height: 36px !important;
    font-family: revert !important;
}

/* .add-page-button:hover {
    color: #fff !important;
    background-color: #5E81F4 !important;
} */

.eco-progress__bar {
    width: 100vw !important;
}

.header_tag {
    padding: 20px;
    border-bottom: 1px solid #D8D8D8;
}
.settings-options{
    padding: 10px;
}
.settings-options:hover{
    background-color: #E6F6FF;
    cursor: pointer;
}
.description-box{
    background-color: #f4f4f4!important;
    border-color: transparent!important;
}
.ant-tree-node-selected{
    border-color: #039BE5!important;
    color: #039BE5!important;
    background-color: #fff!important;
}
.ant-tree-node-selected .eco-icon{
    fill: #039BE5!important;
}
.ant-tree-node-content-wrapper, .ant-tree-switcher{
    padding: 10px!important;
}