.settings__overlay{
    width: 352px;
    height: 100%;  
    position: fixed;
    z-index: 1;
    top: 162px;
    left: 65px;
    overflow-x: hidden;
    transition: all .6s ease-in;
    background-color: #ffffff;
    box-shadow: 0px 0px 0px #5a5a5a;
    border-top: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    z-index: 99991;
    overflow: scroll;
}
.settings-box{
    width: 300px;
    height: 100px;
    border: 1px solid #ECECF2;
    padding: 10px 30px;
    border-radius: 10px;
    margin:0!important;
}

.settings-section{
    padding: 20px;
}

.settings-div{
    border: 2px solid #f0f0f0;
    border-radius: 6px;
}

.settings-card{
    padding: 10px;
    padding-bottom: 20px;
}

.main-title{
    border-left: 5px solid #EEEEEE;
    padding-left: 8px;
}

.h-line{
    background: #f0f0f0;
    margin:0 auto;
    padding: 0;
}
.settings-card{
    border: 1px solid #C6C7D2;
    padding: 10px;
}
.save-btn, .cancel-btn{
    width: 80px;
}
.unpublish-btn, .unpublish-cancel-btn{
    width: 150px;
}
.theme-selection{
    padding: 10px;
}
.theme-selection:hover{
    background-color: #E6F6FF;
    color: #039BE5!important;
    cursor: pointer;
}
.ant-tabs-tab-btn{
    width: 100%;
}