.layout-mobile .react-grid-layout{
    margin: 10px;
    min-height: 640px !important; 
    overflow-y: auto;
    max-height: 640px !important;
    max-width: 360px;
    margin: auto;
    height: fit-content; 
    outline: 20px solid #000;
    border-radius: 20px;
    overflow-x: hidden;

}