.eco-layout .eco-layout__content {

  overflow: auto !important;
}

.react-grid-layout {
  margin: 10px;
  min-height: 100vh !important;
}

.home__right__panel {
  width: 30% !important;
  border: 1px solid black;
}


.PreviewLayout .ant-row:hover {
  cursor: move;

}

.widget__class {
  width: 86px !important;
  height: 85px !important;
  margin-bottom: 8px;
  margin-inline-end: 15px;
}

.widget__class_div {
  margin-bottom: 10px !important;
  display: flex;
  padding: 0 20px;
  margin-block-start: 10px;
  transform: translate(2px, 0);
}

.widget__inside {
  display: flex;
  flex-direction: column;

}

.icon__module__main {
  flex: 0 0 auto;
  height: 45px;
  margin-top: 4px;
  fill: #617379;
}

.icon__module {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  max-width: 100%;
}



/* .widget__name {
    text-align: center;
    color: inherit !important;
    font-size: 13px;
    line-height: 1.1;
    padding-inline-end: 5px;
    padding-inline-start: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 5px;
     
  } */
/* .eco-right-panel__overlay {
    background-color: #fff !important;
    opacity: 0 !important;
    transition: .4s !important;
    width: 100% !important;
    z-index: 0 !important;
} */


/*  */
.reportWidgets {
  width: 100px;
  height: 100px;
}

/* DELETE LATER */

.uploader {
  border: 2px dashed #cecece;

  border-radius: 10px;
  min-height: 150px;
  padding: 10px;
}

.file-text {
  margin: 31px;
  padding: 6px 126px;

  text-align: center;

}

/* .uploader > input {
  display: none;
} */
/* DELETE LATER */
/* 
.border-background {
  width: auto !important;
  height: auto !important;
} */
/* .PreviewLayout .layout{
  height: 100% !important;
}
.PreviewLayout .layout .react-grid-item > div{
  width: 97% !important;
} */


/* CLOSE ICON */
.hide-button {
  position: absolute;
  right: 2px;
  top: -9px;
  cursor: pointer;
}

.eco-button.eco-button--default:focus { 
  box-shadow: none !important;
}

/* REACT GRID ITEM */
.react-grid-item {
  box-sizing: border-box;
  overflow: hidden!important;
}

.react-grid-item:not(.react-grid-placeholder) {
  border-radius: 5px;
  /* padding: 30px; */
  /* width: 200px; */
  /* height: 150px; */
  /* border: 1px solid rgb(206, 206, 206); */
}

.react-grid-item.resizing {
  opacity: 0.9;
}

.react-grid-item.static {
  background: #cce;
}

.react-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
}

.react-grid-item .minMax {
  font-size: 12px;
}

.react-grid-item .add {
  cursor: pointer;
}

.global-header {
  width: 100%;
}

/* .preview__btn__header:hover {
  background-color:  rgba(94,129,244,10%) !important;  
  color: #5E81F4 !important; 
} */


.preview__btn__header {
  color: #fff !important;
  background-color: #5E81F4 !important;
  border: #5E81F4 !important;
  width: 112.77px !important;
  height: 36px !important;
  border-radius: 8px !important;
}

.size__dropdown {
  background: #EAEAF1 !important;
  border: #EAEAF1 !important;
}

.size__dropdown .eco-typography {
  font-weight: 500 !important;
}

.react-grid-layout {
  background-color: #fff;
}

/* HEADER PANEL */
.tablet-rotate {
  transform: rotate(-90deg);
}

.colored-view {
  fill: #039BE5 !important;
}

.colored-view-tab {
  fill: #039BE5 !important;
  transform: rotate(-90deg);

}
.home-page-content{
  background-color: #e5e5e7;
  padding: 20px;
  max-height: calc(100vh - 132px);
  overflow-y: scroll;
}
.layout-mobile{
  padding: 50px;
}
.publish-btn{
  width: 150px;
}

body > iframe {
  pointer-events: none;
}