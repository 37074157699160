input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield !important; /* Firefox */
}

.image_validation{
    color: red;
}

.eco-select .eco-select__control{
    background: #f2f2f2;
    border: 1px solid #f2f2f2;
}