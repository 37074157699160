.custom-textarea {
  background: #f2f2f2 !important;
  border-color: #f2f2f2 !important;
}
.selected-layout {
  border: 1px solid #039be5;
}
.top-right-icon {
  rotate: -90deg;
}
.selected-border-radius {
  width: fit-content;
  background: aliceblue;
  background: #e6f6ff;
  border-radius: 4px;
  padding: 6px;
}

.card-layout__two-title {
  margin: 1.5rem 0 0 2rem !important;
  display: block;
  text-align: left;
  width: 100%;
}

.card-layout__two-img {
    width: 100px;
    height: 100px;
    margin: 1.5rem 0 0 2rem !important;
}