.one-col-layout{
    padding: 20px;
}
.one-col-layout .product-row{
    border-bottom: 1px solid #D3D3D3;
    padding: 24px 0px;
}

.two-col-layout{
    /* padding: 20px; */
    background: rgb(255, 255, 255);
}
.two-col-layout .product-row{
    border-bottom: 1px solid #D3D3D3;
    padding: 24px 0px;
}
.product-tabs .ant-tabs-tab-active .ant-tabs-tab-btn{
    color: transparent !important;
    font-size: 16px;
}
.product-tabs .ant-tabs-tab-active{
    background: transparent!important;
}
.product-tabs .ant-tabs-nav-list{
    border-color: #fff!important;
    width: 100%!important;
}
.product-tabs .ant-tabs-ink-bar{
    /* background: #FD8F0E!important; */
}
.product-tabs .ant-tabs-content-holder{
    border: transparent!important;
}
.eco-product-label{
    line-height: initial;
}

