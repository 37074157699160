.layout-tablet .react-grid-layout{
    margin: 10px;
    min-height: 961px !important; 
    overflow-y: auto;
    max-height: 961px !important;
    max-width: 834px;
    margin: auto;
    height: fit-content; 
    outline: 35px solid #000;
    border-radius: 20px;
    overflow-x: hidden;

}
.layout-tablet{
    padding: 50px;
}