
.video-upload-section {
    border: 2px dashed #cecece;
    border-radius: 10px;
    min-height: 150px;
    padding: 10px;
}
.eco-button.eco-button--subtle:active,
 eco-button.eco-button--subtle:focus{
    box-shadow: none;
}
.eco-button.eco-button--subtle:hover {
    background-color: transparent !important;
}