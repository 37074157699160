.selected-theme{
    border: 1px solid #039BE5 !important;
}
#font-picker-button .dropdown-button{
    background: #f2f2f2!important;
}

#font-picker-items .dropdown-button{
    background: #f2f2f2!important;
}

#font-picker-category .dropdown-button{
    background: #f2f2f2!important;
}

#font-picker-subcat .dropdown-button{
    background: #f2f2f2!important;
}

#font-picker .dropdown-button{
    background: #f2f2f2!important;
}

#font-picker .dropdown-button{
    background: #f2f2f2!important;
}
#font-picker-button{
    height: 3.6 rem !important;
    box-shadow: none !important;
    width: 100% !important;
}

#font-picker-items{
    height: 3.6 rem !important;
    box-shadow: none !important;
    width: 100% !important;
}

#font-picker{
    height: 3.6 rem !important;
    box-shadow: none !important;
    width: 100% !important;
}

#font-picker-subcat{
    height: 3.6 rem !important;
    box-shadow: none !important;
    width: 100% !important;
}
#font-picker-category{
    height: 3.6 rem !important;
    box-shadow: none !important;
    width: 100% !important;
}
.rw-popup-container, .sketch-picker{
    z-index: 1000000!important;
}