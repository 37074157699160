.tab-item{
    padding: 10px;
    cursor: pointer;
}
.selected-tab-item span{
    font-weight: bold;
    text-decoration: underline!important;
}
.tab-content-container{
    padding: 20px;
}