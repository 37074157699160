@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap);
@import url(https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap);
a {
  text-decoration: none !important;
}

.App {
  text-align: center;
}

.Full {
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.site-card-border-less-wrapper {
  padding: 20px;
  background: #ECECEC;
}

.droppable-element {
  width: 150px;
  text-align: center;
  background: #fdd;
  border: 1px solid #aaa;
  margin: 10px 0;
  padding: 10px;
}

.react-grid-layout {
  position: relative;
  border: 1px solid #aaa;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item:hover {
  border: 1px solid #168df8;
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item.cssTransforms {
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item>.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item>.react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable-hide>.react-resizable-handle {
  display: none;
}

#x {
  position: absolute;
  color: #000;
  font-size: x-small;
  background: #FFF;
  border-radius: 20px;
  top: -5px;
  right: -5px;
  box-shadow: 1px 2px 5px rgb(174, 175, 175) inset, 1px 2px 5px rgb(174, 175, 175);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.ant-table-pagination{
  margin: 0!important;
  background-color: #fff;
  padding: 16px!important;
}

.eco-header__location-switcher .eco-stack__item{
  max-width: 0 10px !important;
}

.site-layout {
  width: 85%;
  min-width: 1000px !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.show-grid .row>.col,
.show-grid .row>[class^=col-] {
  padding-top: .75rem;
  padding-bottom: .75rem;
  background-color: #FFF;
}

.preview div,
.preview>* {
  padding: 0px !important;
}

.preview .col-sm-8 {
  max-width: 100% !important;
}

.MuiBox-root {
  text-align: center;
}

/* .preview{
  padding-left: 35px !important;
} */


/* .bg-light {
  background-color: #191529 !important;
} */

.navbar-light .navbar-nav .nav-link {
  color: rgb(0, 0, 0);
}

/* .navbar-light .navbar-nav .nav-link:hover {
  color: #dcae0a;
} */

.topleftbanner {
  padding-right: 0;
}

.torightbanner {
  padding-left: 0;
}

.mainbanner {
  padding: 0 3px 0 3px;
}

.footer {
  background-color: #393939 !important;
  color: #e6e6e6;
  padding-top: 48px;
}

.newsletter-subscribe {
  background-color: #f5f5f5 !important;
  color: rgb(0, 0, 0);
  padding: 15px;
}

.footer h5 {
  padding: 0 0 16px;
  font-family: MontrealSerial-Bold, sans-serif;
  font-size: 14px;
  cursor: auto;
  color: #fff;
}

h2 {
  font-family: MontrealSerial-Bold, sans-serif;
  font-size: 18px;
  font-weight: bold;

}

.linkGroup_3qKNM ul {
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style: none;
}

.linkGroup_3qKNM li {
  margin: 0;
  padding: 0 0 16px;
  line-height: 1;
}

.linkGroup_3qKNM li a {
  color: #e6e6e6;
  font-family: MontrealSerial-Bold, sans-serif;
  font-size: 12px;
}

.Nav-banner {
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  -webkit-padding-start: 40px;
          padding-inline-start: 40px;
}

.css-1jvu78r {
  font-size: 14px;
  display: inherit;
  min-width: 0px;
  margin-left: 0.5em;
}

.mini-cart-total-label sup {
  background: #ff0000;
  color: #fff;
  padding: 2px 8px;
  border-radius: 50%;
  font-size: 14px;
  top: 0em;
}

.card svg {
  color: rgb(165, 165, 165);
  float: right;
  margin: 2px;
}

.card span svg {
  color: rgb(165, 165, 165);
  float: none;
  margin: 2px;
}

.copyright {
  padding: 16px 0 16px 0;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto;
  text-align: center;
  font-family: arial;
}

.card input {
  width: 100%;
  height: 46px;
  text-align: center;
  font-size: 18px !important;
}

.react-numeric-input {
  width: 30%;
  height: 46px;
  background-color: #e6e6e6;
}

.inputandbtn {

  text-align: center;
}

.card button {
  font-family: montrealserial-regular, sans-serif !important;
  width: 60% !important;
  background-color: #caa622 !important;
  font-size: 15px !important;
  margin-top: 10px;
}

.card button svg {
  float: left;
  color: #fff !important;
}

.divLoader {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.cardtxt {
  font-family: montrealserial-regular, sans-serif !important;
  font-size: 14px;
}

.card img {
  margin: 15px 5px 0 5px;
}

.card .prductimgbg {
  margin: 10px !important;
}

.card h4 {
  font-family: montrealserial-regular, sans-serif;
  padding-top: 10px;
  font-weight: bold;
  font-size: 15px;
}

.priceright {
  margin-top: 18px;
  ;
}

.price {
  color: #39464e;
  font-size: 48px;
}

.price sup {
  color: #39464e;
  font-size: 15px;
}

.price sup.pricex {
  color: #39464e;
  font-size: 12px;
}

.disprice {
  color: #c20e1a;
  font-size: 48px;
}

.disprice sup {
  color: #c20e1a;
  font-size: 15px;
}

.disprice sup.pricex {
  color: #c20e1a;
  font-size: 12px;
}

.dispricetxt {
  color: #c20e1a;
}

.card button {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

.container-fluid {
  height: 94% !important;
}

.ant-popover {
  width: 250px;
}
.ant-popover-arrow{
  display: none!important;
}

.card button:hover {
  opacity: 0.7;
}

.homeMessage {
  font-family: montrealserial-regular, sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: .05em;
  font-size: 12px;
  line-height: 1.385;
}

.homeh2Message {
  font-family: montrealserial-regular, sans-serif;
  letter-spacing: 0.5px;
  font-size: 20px;
  line-height: 1.15;
  margin-top: 25px;
}

.accleft {
  padding-right: 0px;
}

.accright {
  padding-left: 0px;
}

.col-sm-3 .ant-menu {
  width: 100% !important;
}

.alert .ant-radio-group {
  width: 100%;
}

.quantity {
  display: flex;
  width: 160px;
}

/* it will support chrome and firefox */
.quantity input[type=number]::-webkit-inner-spin-button,
.quantity input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.quantity input,
.quantity button {
  width: 40px;
  padding: .1em;
  text-align: center;
  font-weight: 900;
  background: white;
  border: 1px solid #aaa;
}

.quantity input {
  border-left: none;
  border-right: none;
}

.or-icon {
  padding-left: 110px !important;
  padding-top: 100px !important;
}

.ant-menu-submenu-title span {
  vertical-align: middle;
}

.basketadded {
  font-family: montrealserial-regular, sans-serif;
  color: #52c41a;
}

.basketadded svg {
  vertical-align: baseline;
}

#x {
  position: absolute;
  color: #000;
  font-size: x-small;
  background: #FFF;
  border-radius: 20px;
  top: 0px;
  right: 0px;
  z-index: 1000;
  box-shadow: 1px 2px 5px rgb(174, 175, 175) inset, 1px 2px 5px rgb(174, 175, 175);
}

.ant-drawer-content {
  background-color: transparent;
}

.text {
  position: absolute;

}
/* 
.disBlock {
  display: block;
}

.disNone {
  display: none;
} */

/* .rowremove {
  position: absolute;
  background-color: #1890ff;
  top: 0;
  left: 0;
  z-index: 999;
  width: auto;
  height: auto;
  color: white;
  padding: 3px;
  border-radius: 1px;
  text-align: center;
} */

.rowadd {
  background-color: #ff1878;
  width: 25px;
  z-index: 1680;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: white;
  padding-bottom: 10px;
  height: 24px;
  border-radius: 25px;
}

.rowadd:hover {
  transition: width 180ms ease-in-out;
  width: 10%;
}

.mce-content-body {
  border: 0px solid #1890ff;
}



.iframe {
  display: none !important;
}

.retailpax-sidemenu .ant-menu .ant-menu-item {
  min-height: 50px !important;
}

.eco-card{
  width: auto !important;
  height: 100% !important;
}
.ant-modal-header{
  background-color: #F0F1F4!important;
}
.ant-tabs-tab-active{
  background:  #E6E7EC!important;
}
.ant-tabs-tab-active .ant-tabs-tab-btn{
  font-weight: 500!important;
}

.pac-container{
  z-index: 9999999 !important;
}

/*
map widget
*/

.map-widget .eco-card--body{
  padding: 0 !important;
}
.widget-delete-confirmation-popup .ant-modal-header{
  background-color: transparent!important;
  border-bottom: none!important;
}
.ant-modal-mask{
  z-index: 9999999999999999999999!important;
}
.eco-progress{
  overflow: hidden;
}
.ant-tabs-nav{
  margin: 0!important;
}
.ant-modal-footer{
  border-top: transparent!important;
}
.ant-tabs-content-holder{
  border: 1px solid #C6C7D2;
  border-radius: 0 0 4px 4px;
}
.sketch-picker {
  z-index: 99!important;
}
.ant-tooltip{
  z-index: 999999999999999999999999999999999999!important;
}
.ant-message{
  z-index: 999999999999999999999999999999999999!important;
}
 
.Apparel-casi body {
 font-size: 14px;
 /* font-family: 'Poppins', sans-serif; */
 padding: 0;
 margin: 0;
 background-color: #ffffff;
}
.Apparel-casi .container-fluid{
    padding-right: 0px;
    padding-left: 0px;
}
/* Overlay Background -------------------------------------------------------------- */
.Apparel-casi .site-overlay {
 position: fixed;
 left: 0;
 top: 0;
 height: 100%;
 width: 100%;
 z-index: 1010;
 display: none;
 background-color: rgba(206, 157, 55, 0.55);
}
/* Site Brand ---------------------------------------------------------------------- */
.Apparel-casi .site-brand {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
	background-color: #faf5e9;
}
.Apparel-casi .site-brand .inner-right {
    display: flex;
    font-size: 16px;
}
/* Site Header Inside -------------------------------------------------------------- */
.Apparel-casi .search-control {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding: 20px 10px;
	z-index: 1011;
	display: none;
}
.Apparel-casi .showSearch .search-control {display: block;}
.Apparel-casi .search-control input {
	border: 0;
	font-weight: lighter;
}
.Apparel-casi .search-control input:focus, .search-control input:active {
	border-color: #ce9d37;
	box-shadow: none;
}
.Apparel-casi .search-control button {
	background-color: #ce9d37;
	border: 1px solid #ce9d37;
    border-radius: .25rem;
	border-top-left-radius: 0;
    border-bottom-left-radius: 0;
	color: #ffffff;
	font-weight: 500;	
}
.Apparel-casi .search-control button:hover {color: #ffffff;}
.Apparel-casi .search-control button:focus {box-shadow: none;}
.Apparel-casi .inner-left {display: flex; align-items: center;}
.Apparel-casi .logo img {height: 75px;}
.Apparel-casi .header-right {display: flex;align-items: center;}
.Apparel-casi .btn-search {
	color:#ce9d37;
	font-size: 20px;
	margin-right: 5px;
}
.Apparel-casi .header-right .Apparel-casi .top-link-wrapper {position: relative;}
.Apparel-casi .header-right ul {transition: 0.3s;}
.Apparel-casi .header-right ul a {
 text-decoration: none;
 padding: 0 5px; 
 display: flex;
 align-items: center;
 height: 100%;
 color: #000000;
}
.Apparel-casi .header-right ul a .favourite-items i,
.Apparel-casi .header-right ul a .shopping-cart-items i,
.Apparel-casi .header-right ul a .user-icon i
{font-size: 20px;}
.Apparel-casi .bg-round {
	color: #ce9d37;
	background-color: #ffffff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
	transition: all .3s;
}
.Apparel-casi .bg-round:hover {background-color: #fcf3e1;}
.Apparel-casi .header-right ul a.currency-switch {
	padding-right: 0;
	margin-right: 5px;
}
.Apparel-casi .currency-switch .currency-name {
 font-size: 14px;
 display: inline-block;
 max-width: 70px;		
 white-space: nowrap; 
 text-overflow:ellipsis;
 overflow: hidden;
 text-align: right;
 color: #ce9d37;
} 
.Apparel-casi .currency-switch .currency-text {
	display: flex;
	flex-direction: column;
}
.Apparel-casi .mobile-menu {display:flex;margin-left: 10px;}
.Apparel-casi .mobile-menu a {
	text-decoration: none;
    padding: 0 5px;
    color: #ce9d37;
}
.Apparel-casi .mobile-menu a i {font-size: 20px;}
.Apparel-casi .count {
	position: absolute;
	right: 0;
	bottom: -3px;
	font-size: 10px;
	font-weight: 700;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	line-height: 16px;
	text-align: center;
	color: #ffffff;
	background-color: #f60303;
}
.Apparel-casi .loggedin-user {
	padding: 5px 10px;                            
	text-align: center;
	color: #ce9d37;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 0;
}
.Apparel-casi .hr-sep {display: flex; justify-content: center;}
.Apparel-casi .hr-sep hr{
	border-color: #d9d9d9;
	margin: 5px 0;
	width: 75%;
}
.Apparel-casi .header-right ul .dropdown-menu  a {padding:7px 10px;border-radius: .25rem;}
.Apparel-casi .header-right ul li .dropdown-menu a {
	font-size: 14px;
	padding: 7px 20px;
	position: relative;
    white-space: initial;
    line-height: 1.25;
	color: #8d6717;
}
.Apparel-casi .header-right ul .dropdown-menu a:hover {color: #8d6717;background-color: #f9f9f9;}
.Apparel-casi .header-right ul li .dropdown-menu .dropdown-item i {font-size: 16px;}
.Apparel-casi .profile-name {margin-left:5px; display:none;}
/* overriding default bootstrap values */
.Apparel-casi .row {margin:0;padding:0}
.Apparel-casi .dropdown-menu {
	z-index: 1040;
	background-color: #ffffff;
}
.Apparel-casi .dropdown-menu.show {
	margin-top: 3px;
	border-radius: 0;
	box-shadow: 0 1px 3px rgba(0,0,0,.2);
	padding:5px 0;
	border:0;
}
.Apparel-casi .dropdown-menu.show::after {
	border-bottom: 8px solid;
	border-color: #ffffff;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
	top:-8px;
   	content: "";
    display: inline-block;
    right:8px;
    position: absolute;
    z-index: 1001; 
}
.Apparel-casi .drop-down-icon-hide::after{display: none;}
.Apparel-casi .dropdown-toggle::after {color: #ce9d37;}
/* Top Navbar ---------------------------------------------------------------------- */
.Apparel-casi .top-navbar-wrapper {
	position: fixed; 
   	top:0;
	width: 300px;
	left: -300px;
 transition: all .3s;
	z-index: 539;
}
.Apparel-casi .mobile-nav-menu {overflow: hidden;}
.Apparel-casi .mobile-nav-menu .top-navbar-wrapper {left:0;}
.Apparel-casi .top-navbar {
	background-color: #faf5e9;
 	width: 100%;
    display: flex;
    justify-content: flex-start;
}
.Apparel-casi .mobile-nav-menu .site-overlay, .showSearch .site-overlay {display:block;}
.Apparel-casi .top-navbar .top-nav {
	height:100vh;
	overflow: auto;
	margin:0;
	padding: 0 0 60px 0;
	list-style: none;
	width: 100%;
}
.Apparel-casi .top-navbar .top-nav li {
	display:block;
	width:100%;
	height:auto;
	list-style: none;
	margin:0;
	position: relative;
}
.Apparel-casi .top-navbar .top-nav li a {
	line-height: 30px;
	display: block;
    padding:10px;
    font-size: 16px;
    font-weight: 600;
    text-decoration:none;
	color: #ce9d37;
	white-space: nowrap;
}
.Apparel-casi .top-navbar .top-nav li.active, 
.Apparel-casi .top-navbar .top-nav li.focus,
.Apparel-casi .top-navbar .top-nav li:hover {
	background-color: #ffffff;
}
.Apparel-casi .top-navbar .top-nav .dd-list a {padding-right: 30px;}
.Apparel-casi .top-navbar ul.top-nav li.dd-list::after{
    font-family: "Font Awesome 5 Free"; 
    font-weight: 900; 
    content: "\f0d7";
    position: absolute;
    right: 10px;
    top: 15px;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
	color: #ce9d37;
}
.Apparel-casi .top-navbar ul.top-nav li.dd-list.active::after{-webkit-transform: rotate(360deg);transform: rotate(360deg);}
.Apparel-casi .top-navbar .dd-list .ddl-menu {
	display:none;
	margin:0;
	padding: 10px 0;
	position: relative;
    top: 100%;
    z-index: 1000;
  	box-shadow: 0 2px 5px rgba(0,0,0,.15);
  	transition: 0.3s;
	background-color: #ffffff;
}
.Apparel-casi .top-navbar .dd-list ul.ddl-menu li {
 list-style: none;
}
.Apparel-casi .top-navbar .dd-list ul.ddl-menu li a {
 margin:0;
 padding: 15px 10px;
 line-height: initial;
 white-space: nowrap;
 font-size: 16px;
 font-weight: 400;
 display: block;
 width:100%;
 color: #ce9d37;
}
.Apparel-casi .top-navbar .dd-list ul.ddl-menu li a:hover, 
.Apparel-casi .top-navbar .dd-list ul.ddl-menu li a:focus {
 background-color: #f6f6f6;
 color: #ce9d37;
}
.Apparel-casi .top-navbar .dd-list.active .ddl-menu.open {
 display:block;
}
.Apparel-casi .top-navbar .dd-list.active .ddl-menu .last-active {
 font-weight: 700;
}
/* Welcome Message ----------------------------------------------------------------- */
.Apparel-casi .welcome-message {
	margin-top: 20px;
	padding: 15px;
}
.Apparel-casi .welcome-message .welcome-message-inner {
	box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 10%);
    border-radius: 8px;
	width: 100%;
	margin: 0 auto;
	background-color: #ffffff;
	background-image: url(/static/media/welcome-bg.6d320eb3.png);
	background-repeat: no-repeat;
    background-position: left bottom;
}
.Apparel-casi .welcome-message h4 {
	text-align: center;
	margin: 0;
	padding: 15px 0;
}
.Apparel-casi .welcome-message .message-wrapper {padding-bottom: 10px;}
.Apparel-casi .welcome-message .message-wrapper p {
	font-size: 14px;
	line-height: 1.5rem;
	margin: 0;
	padding: 0;
}
/* Our Collections Large ----------------------------------------------------------- */
.Apparel-casi .our-collections {display:none !important;}
.Apparel-casi .our-collections .collection-imgs {position: relative;}
.Apparel-casi .our-collections .collection-imgs .img-desc {
	position: absolute;
	top: 10px;
	left: 10px;
	right: 10px;
	padding: 10px;
	background-color: rgba(206,157,55,0.57);
	border-radius: 4px;
	color:#ffffff;
}
.Apparel-casi .our-collections a {text-decoration: none;}
.Apparel-casi .our-collections h4 {text-align: center;}
.Apparel-casi .our-collections .collection-text {
	width: 100%;
	background-color: #ce9d37;
	color: #ffffff;
	padding: 10px;
}
.Apparel-casi .mt15 {margin-top: 20px !important;}
.Apparel-casi .our-collections .collection-text h6 {font-size: 16px;}
.Apparel-casi .our-collections .collection-text p {
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 0;
	min-height: 55px;
	max-height: 55px;
	overflow: hidden;
}
.Apparel-casi .our-collections .collection-text p.full-w {
	min-height: unset;
	max-height: unset;
}
.Apparel-casi .mr15 {margin-right: 15px;}
.Apparel-casi .our-collections .collection-title a:hover {background-color: rgba(206,157,55,1);}
/* Our Collections / Image Gallery ------------------------------------------------- */
.Apparel-casi .our-collections .collection-img .zoom-img {overflow: hidden;}
.Apparel-casi .our-collections .collection-img img {
	width: 100%;
	max-width: 100%;
	height: auto;
	transition: all 0.5s;
	height: 100%;
}
/* Our Collections Mobile ---------------------------------------------------------- */
.Apparel-casi .our-collections-mobile {margin-top: 20px;}
.Apparel-casi .our-collections-mobile h4 {text-align: center;}
.Apparel-casi .our-collections-mobile .item-text {
	width: 100%;
    background-color: #ce9d37;
    color: #ffffff;
    padding: 10px;
}
.Apparel-casi .our-collections-mobile .item-text h6 {font-size: 14px;font-weight: 600;}
.Apparel-casi .our-collections-mobile .item-text p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0;
    min-height: 70px;
    max-height: 70px;
    overflow: hidden;
}
.Apparel-casi .our-collections-mobile a {text-decoration: none;}
/* Promotions ---------------------------------------------------------------------- */
.Apparel-casi .promotions {
	margin-top: 20px;
}
.Apparel-casi .promotions h4 {text-align: center;}
.Apparel-casi .promotions .promo-item a {
	width: 100%;
	height: 100;
}
/* image zoom */
.Apparel-casi .promotions .promo-item a img {
	transition: all 0.3s;
	-webkit-transform: scale(1);
	        transform: scale(1);
}
.Apparel-casi .promotions .promo-item a img:hover {
	-webkit-transform: scale(1.3);
	        transform: scale(1.3);
}
/* /image zoom */
.Apparel-casi .promotions .promo-item .promo-item-wrapper {position: relative;overflow: hidden;}
.Apparel-casi .promotions .promo-item .discount {
	position: absolute;
    top: 20px;
    right: 20px;
    z-index: 3;
    background-color: rgba(206,157,55,.7);
	color: #ffffff;
    width: 50px;
    height: 50px;
    border-radius: 100%;
	font-size: 14px;
	line-height: 18px;
    font-weight: 700;
    text-align: center;
    -webkti-box-sizing: border-box;
    box-sizing: border-box;
	display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.Apparel-casi .promotions .promo-item .promo-name {
	position: absolute;
	bottom: 20px;
	left: 0;
	background-color: rgba(206,157,55,.7);
	padding: 10px;
	color:#fff;
	font-size: 14px;
	font-weight: 600;
	width:100%;
}
/* owl carousel style overriding */
.Apparel-casi .owl-theme .owl-dots .owl-dot span {
	background: none;
	border: 1px solid #ce9d37;
}
.Apparel-casi .owl-theme .owl-dots .owl-dot.active span, .Apparel-casi  .owl-theme .owl-dots .owl-dot:hover span {
	background: #ce9d37;
}
.Apparel-casi .owl-dots button:focus {outline: none !important;}
.Apparel-casi .owl-theme .owl-nav [class*=owl-]:hover {
	background: none;
	color: #ce9d37;
	text-decoration: none;
}
.Apparel-casi .owl-nav button:focus {outline: none !important;}
.Apparel-casi .owl-nav button i {font-size: 20px;}
.Apparel-casi .owl-nav {margin-bottom:10px;}
/* Make Appointment/Weaving Process ------------------------------------------------ */
.Apparel-casi .appointment {
	margin-top: 20px;
}
.Apparel-casi .appointment h4 {
	text-align: center;
	font-size: 20px;
}
.Apparel-casi .appointment .inner-wrap {
	display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
	border-radius: 8px;
	background-color: #fff;
	padding: 20px;
	margin-bottom: 20px;
	box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 10%);
}
.Apparel-casi .appointment .inner-wrap p {
	padding: 10px 0;
	margin-bottom: 0;
	text-align: center;
	font-size: 16px;
	line-height: 24px;
}
/* Testimonials - Home Page -------------------------------------------------------- */
.Apparel-casi .testimonials {
	margin-top: 20px;
}
.Apparel-casi .testimonials h4 {text-align: center;}
.Apparel-casi .testimonials .testimonials-item {background-color: #ffffff;}
.Apparel-casi .testimonials .testimonials-item-inner {
	border:1px solid #e8e8e8;
	border-radius: 8px;
	padding: 10px;
	display: flex;
	flex-direction: column;
}
.Apparel-casi .testimonials .testimonials-user {
	display: flex;
}
.Apparel-casi .testimonials .testimonials-item img  {
	border-radius: 50%;
	width: 50px;
}
.Apparel-casi .testimonials .user-name {font-weight: 600;font-size: 14px;}
.Apparel-casi .testimonials .user-rating { padding: 0 10px;}
.Apparel-casi .testimonials .user-rating .rating-starts {
 color: #FDCC0D;
 font-size: 10px;
}
.Apparel-casi .testimonials .testimonials-text {
	padding: 10px 0;
	margin-bottom: 0;
	color: #818181;
}
.Apparel-casi .testimonials .btn-view {
	display: flex;
    justify-content: center;
}
/* Site Footer --------------------------------------------------------------------- */
.Apparel-casi .footer-hr {border-top: 1px solid #d9d9d9;}
.Apparel-casi .site-footer {
	background-color: #faf5e9;
	margin-top: 50px;
}
.Apparel-casi .site-footer .text-footer {
	margin: 20px 0;
	font-weight: 700;
	color: #ce9d37;
}
.Apparel-casi .site-footer .contact-info {color:#818181;}
.Apparel-casi .site-footer .copytext {
	text-align: center;
	color: #818181;
	font-size: 12px;
	padding-bottom: 20px;
	padding-top: 5px;
}
.Apparel-casi .site-footer .contact-info ul {
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
}
.Apparel-casi .site-footer .contact-info ul li {
	line-height: 24px;
	font-size: 14px;
}
.Apparel-casi .site-footer .contact-info ul.cont-direct {
	margin-top: 20px;
}
.Apparel-casi .site-footer .contact-info ul.social-media-links {
	margin-top: 20px;
}
.Apparel-casi .site-footer .contact-info ul.social-media-links li {
	display: inline-block;
	
}
.Apparel-casi .site-footer .contact-info ul.social-media-links li a {
	padding: 0 10px 10px 0;
	color: #818181;
	font-size: 30px;
}
.Apparel-casi .site-footer .contact-info ul.social-media-links li a:hover {color: #000000;}
.Apparel-casi .site-footer .contact-info .cards-text {
	margin-top:20px;
	margin-bottom: 5px;
	font-size: 14px;
}
.Apparel-casi .site-footer .contact-info ul.pay-cards li {
	display: inline-block;
	margin-right: 5px;
}
.Apparel-casi .site-footer .contact-info ul.pay-cards i {
	font-size: 40px;
}
.Apparel-casi .site-footer .contact-info ul.pay-cards .visa_cd {
	color: #0066b1;
}
.Apparel-casi .site-footer .contact-info ul.pay-cards .mast_cd {
	color: #374961;
}
.Apparel-casi .site-footer .contact-info ul.pay-cards .amex_cd {
	color: #006ecf;
}
.Apparel-casi .site-footer .user-agreement {
	text-align: center;
    font-size: 12px;
    padding-bottom: 20px;
    padding-top: 5px;
}
.Apparel-casi .site-footer .user-agreement a {
	text-decoration: none;
	color:#818181;
	margin-right: 10px;
}
.Apparel-casi .site-footer .user-agreement a:hover {text-decoration: underline;}
.Apparel-casi .mt20 {margin-top: 20px;}
/* Map */
.Apparel-casi .site-footer .map-responsive{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}
.Apparel-casi .site-footer .map-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}
/* Common Styles ------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------- */
/* required fields */
.Apparel-casi .required::after {
    content: " *";
    color: #ff1200;
}
/* button styles */
.Apparel-casi .btn-act-primary {
	text-decoration: none;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
	padding: 5px 10px;
	min-width: 100px;
	color: #ffffff;
	background-color: #ce9d37;
	border: 2px solid;
	border-color: #ce9d37;
	border-radius: 4px;   
    transition: all .7s;
}
.Apparel-casi .btn-act-primary:hover {
	text-decoration: none;
	color: #ffffff;
}
.Apparel-casi .btn-act-secondary {
	text-decoration: none;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
	padding: 5px 10px;
	min-width: 100px;
	color: #ce9d37;
	background-color: #ffffff;
	border-color: #ce9d37;
	border: 2px solid;
	border-radius: 4px;   
    transition: all .7s;
}
.Apparel-casi .btn-act-secondary:hover {
	text-decoration: none;
	color: #ce9d37;
	background-color: #ffffff;
	border-color: #ce9d37;
}
/* /button styles */
/* Site Breadcrumb */
.Apparel-casi .breadcrumb-container {padding-top: 20px;}
.Apparel-casi .breadcrumb-in ul.site-breadcrumb {
	margin-bottom: 0;
	padding:0;
	list-style:none;
}
.Apparel-casi .breadcrumb-in ul.site-breadcrumb li {
	display:inline-block;
	padding-right:10px;
	position:relative;
}
.Apparel-casi .breadcrumb-in ul.site-breadcrumb li a {
	color: #ce9d37;
	font-size: 14px;
	text-decoration: none;
}
.Apparel-casi .breadcrumb-in ul.site-breadcrumb li::after {content: '>';font-size: 12px;position: absolute;right: 0;top: 3px;}
.Apparel-casi .breadcrumb-in ul.site-breadcrumb li:last-child::after {content: '';}
/* //Site Breadcrumb */
/**/
/* Custom checkbox */
/* The container */
.Apparel-casi .chbx-holder {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	user-select: none;
  }
  /* Hide the browser's default checkbox */
  .Apparel-casi .chbx-holder input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
  }
  /* Create a custom checkbox */
  .Apparel-casi .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	border-radius: 4px;
  }
  /* Create the checkmark/indicator (hidden when not checked) */
  .Apparel-casi .checkmark:after {
	content: "";
	position: absolute;
	display: none;
  }
  /* Show the checkmark when checked */
  .Apparel-casi .chbx-holder input:checked ~ .checkmark:after {
	display: block;
  }
  /* Style the checkmark/indicator */
  .Apparel-casi .chbx-holder .checkmark:after {
	left: 8px;
	top: 4px;
	width: 7px;
	height: 13px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
  }
/* Red */
.Apparel-casi .chbx-holder.red input:checked ~ .checkmark, .Apparel-casi .chbx-holder.red .checkmark {background-color: #c90642;}
.Apparel-casi .chbx-holder.red:hover input ~ .checkmark {background-color: #c90642;}
/* Green */
.Apparel-casi .chbx-holder.green input:checked ~ .checkmark, .Apparel-casi .chbx-holder.green .checkmark {background-color: #1a8e5d;}
.Apparel-casi .chbx-holder.green:hover input ~ .checkmark {background-color: #1a8e5d;}
/* Maroon */
.Apparel-casi .chbx-holder.maroon input:checked ~ .checkmark, .Apparel-casi .chbx-holder.maroon .checkmark {background-color: #b72971;}
.Apparel-casi .chbx-holder.maroon:hover input ~ .checkmark {background-color: #b72971;}
/* Orange */
.Apparel-casi .chbx-holder.orange input:checked ~ .checkmark, .Apparel-casi .chbx-holder.orange .checkmark {background-color: #be5d1c;}
.Apparel-casi .chbx-holder.orange:hover input ~ .checkmark {background-color: #be5d1c;}
/* Pink */
.Apparel-casi .chbx-holder.pink input:checked ~ .checkmark, .Apparel-casi .chbx-holder.pink .checkmark {background-color: #9c6fb3;}
.Apparel-casi .chbx-holder.pink:hover input ~ .checkmark {background-color: #9c6fb3;}
/* Yellow */
.Apparel-casi .chbx-holder.yellow input:checked ~ .checkmark, .Apparel-casi .chbx-holder.yellow .checkmark {background-color: #d2bb3b;}
.Apparel-casi .chbx-holder.yellow:hover input ~ .checkmark {background-color: #d2bb3b;}
/* Grey Bg*/
.Apparel-casi .chbx-holder.grey-bg input:checked ~ .checkmark, .Apparel-casi .chbx-holder.grey-bg .checkmark {background-color: #d9d9d9;}
.Apparel-casi .chbx-holder.grey-bg:hover input ~ .checkmark {background-color: #c0c0c0;}
/* //Custom checkbox */
/**/
/* Pagination */
.Apparel-casi .pagination {margin-bottom: 0;}
/* pagination colour override */
.Apparel-casi .page-link {
	color: #ce9d37;
}
.Apparel-casi .page-link:hover {
    z-index: 2;
    color: #ce9d37;
    text-decoration: none;
    background-color: #f6f6f6;
    border-color: #dee2e6;
}
.Apparel-casi .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #ce9d37;
    border-color: #ce9d37;
}
/* Accordion */
.Apparel-casi #accordion .card {
	border-radius: 0;
	border-top:0;
	background-color: #f8f8f8;
	margin-bottom: 3px;
}
.Apparel-casi #accordion .card-header {
	background-color: #d9d9d9;
    cursor: pointer;
    padding: 0;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
	border-radius: 0;
}
.Apparel-casi #accordion .card-header a {
	margin: 0;
	padding: 10px 15px 10px;
	font-size: 14px;
    font-weight: 500;
	color: #212529;
	display: block;
}
.Apparel-casi #accordion .card-header:hover {background-color: #c0c0c0;}
.Apparel-casi #accordion .card-header .btn-link:hover  {text-decoration: none;}
.Apparel-casi #accordion .card-header a:after {
	font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: '\f068';
    position: absolute;
    right: 10px;
    top: 10px;
	font-size: 12px;
}
.Apparel-casi #accordion .card-header a.collapsed:after {
	content: '\f067';
}
/* /Accordion */
/* /Common Styles ------------------------------------------------------------------ */
/* --------------------------------------------------------------------------------- */
/* Product Listing Page ------------------------------------------------------------ */
.Apparel-casi .prd-hero-img img {width: 100%;}
.Apparel-casi .prd-hero {position: relative;}
.Apparel-casi .prd-hero .prd-hero-header {
	position: absolute;
	top: 0;
	padding: 10px;
	background-color: rgba(206,157,55,0.7);
	width: 100%;
}
.Apparel-casi .prd-hero .prd-hero-header h4 {
	font-size: 1rem;
	color: #ffffff;
	margin-bottom: 0;
}
.Apparel-casi .prd-filter-area {
	padding-top: 20px;
}
.Apparel-casi .prd-filter-area .filter-inner {
	background-color: #f3f3f3;
	padding: 15px;
	display: flex;
    flex-direction: column;
}
.Apparel-casi .prd-filter-area .filter-inner .filter-top {
	display: flex;
	flex-direction: column;
}
.Apparel-casi .prd-filter-area .filter-inner .filter-top p {
	margin-bottom: 10px;
	line-height: 20px;
}
.Apparel-casi .prd-filter-area .filter-inner .filter-top .cat-name {
	font-size: 16px;
    font-weight: 600;
}
.Apparel-casi .prd-filter-area .filter-inner .filter-top .cat-count {
	font-size: 16px;
}
.Apparel-casi .prd-filter-area .filter-inner .filter-bottom {
	display: flex;
	flex-direction: column;
}
.Apparel-casi .prd-filter-area .filter-inner .filter-bottom .filter-colour {margin-right: 20px;	min-height: 70px;}
.Apparel-casi .prd-filter-area .filter-inner .filter-bottom p {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 10px;
	display: inline-block;
}
.Apparel-casi .prd-filter-area .filter-inner .filter-bottom .colour-wrapper {display: flex;}
.Apparel-casi .prd-filter-area .filter-inner .filter-bottom .price-ranger {
	margin-left: 10px;
	display: inline-block;
}
/* price slider */
.Apparel-casi .slidecontainer {width: 100%;}
.Apparel-casi .slider {
	-webkit-appearance: none;
	width: 100%;
	height: 5px;
	background: #e7dcc4;
	outline: none;
	opacity: 0.7;
	transition: opacity .2s;
	border-radius: 20px;
  }
  .Apparel-casi .slider:hover {opacity: 1; }
  .Apparel-casi .slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 25px;
	height: 25px;
	background: #ce9d37;
	cursor: pointer;
	border-radius: 3px;
  }
  .Apparel-casi .slider::-moz-range-thumb {
	width: 25px;
	height: 25px;
	background: #ce9d37;
	cursor: pointer;
	border-radius: 3px;
  }
/* /price slider */
.Apparel-casi .prd-list-items {padding-top: 20px;}
.Apparel-casi .prd-list-items .item-wrapper {
	padding: 15px;
	background-color: #ffffff;
	box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
	border-radius: 6px;
	margin-bottom: 20px;
	border: 2px solid #ffffff;
}
.Apparel-casi .prd-list-items .item-wrapper:hover {border-color: #ce9d37;}
.Apparel-casi .prd-list-items .item-wrapper a {text-decoration: none;}
.Apparel-casi .prd-list-items .img-container {
	position: relative;
	margin-bottom: 10px;
}
.Apparel-casi .prd-list-items .img-container img {border-radius: 4px;}
.Apparel-casi .prd-list-items .img-container .item-price {
	border-radius: 4px;
	background-color: #ffffff;
	color:#ce9d37;
	padding: 5px 10px;
	position: absolute;
	bottom: 10px;
	right: 10px;
	font-size: 16px;
}
.Apparel-casi .prd-list-items .item-wrapper .item-text {min-height: 40px;}
.Apparel-casi .prd-list-items .item-wrapper p {
	font-size: 14px;
	margin-bottom: 0;
	color:#ce9d37;
	text-align: center;
}
.Apparel-casi .prd-pagination {
	padding-top: 20px;
	display: flex;
	justify-content: right;
}
/* Product Details Page ------------------------------------------------------------ */
.Apparel-casi .product-details {padding-top:20px;}
.Apparel-casi .product-details .prd-details-img-wrapper {
	display: flex;
	flex-direction: column-reverse;
	margin-bottom: 10px;
}
.Apparel-casi .product-details .prd-details-img-wrapper .prd-img-tbn {display: flex;margin-top: 5px;}
.Apparel-casi .product-details .prd-details-img-wrapper .prd-img-tbn span {margin-right: 5px;}
.Apparel-casi .product-details .prd-details-img-wrapper .prd-img-tbn span img {max-width: 50px;}
.Apparel-casi .product-details .prd-details-text p.prd-title {font-size: 20px;font-weight:600;margin-bottom: 5px;}
.Apparel-casi .product-details .prd-details-text .prd-price {font-size: 20px; font-weight: 500;}
.Apparel-casi .product-details .prd-details-text .prd-more-details {margin-top:10px;}
.Apparel-casi .product-details .prd-details-text .prd-more-details p {margin-bottom: 5px; font-size: 14px;}
.Apparel-casi .product-details .prd-details-text .prd-count {margin-top: 15px;}
.Apparel-casi .product-details .prd-details-text .prd-count .label-qty {
	display: block;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 5px;
}
/* Product Quantity */
.Apparel-casi .prd-count-inner {
	display: flex;
}
.Apparel-casi .prd-count-inner button {
	font-size: 12px;
	color: #212529;
	padding: 5px 10px;
    background-color: #d9d9d9;
    border: 0;
    width: 40px;
    height: 30px;
}
.Apparel-casi .prd-count-inner button:focus {outline: none;}
.Apparel-casi .prd-count-inner .qty-no input {
	width: 40px;
    height: 30px;
	border: 1px solid #d9d9d9;
	background-color: #ffffff;
	font-size: 14px;
	text-align: center;
	font-weight: 500;
}
/* remove scroll in number input*/
.Apparel-casi .qty-no input[type=number]::-webkit-inner-spin-button, 
.Apparel-casi .qty-no input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.Apparel-casi .product-details .prd-details-text .prd-buttons {margin-top: 25px;display: flex;}
.Apparel-casi .product-details .commercial-details {margin-top: 25px;}
/* Shopping Cart Page -------------------------------------------------------------- */
.Apparel-casi .cart-details {padding-top: 20px;}
.Apparel-casi .cart-details .header-wrapper {display:none;}
.Apparel-casi .cart-details h4 { text-align: center; margin-bottom: 20px;}
.Apparel-casi .cart-details .show-cart-count {display: block; margin-bottom: 10px;}
.Apparel-casi .cart-list .cart-count-number {padding: 5px 0;border-top: 1px solid #eee;}
.Apparel-casi .cart-list .cart-count-number span {font-size: 12px; font-weight: 500;}
.Apparel-casi .cart-list .cart-items {
	border-top: 1px solid #eee; 
	/* border-bottom: 1px solid #eee; */
	padding: 10px 0;
}
.Apparel-casi .cart-list .cart-product-name {display: flex;margin-bottom: 10px;}
.Apparel-casi .cart-list .cart-product-name .img-wrap img {
	margin-right: 10px; 
	display: block; 
	max-width: 60px;
}
.Apparel-casi .cart-list .product-name-wrap a.product-name {
    font-size: 14px;
    font-weight: 600;
    display: block;
    color: #212529;
    text-decoration: none;
	line-height: 1;
	margin-bottom: 10px;
}
.Apparel-casi .cart-list .product-name-wrap .product-more-details p {
    margin-bottom: 10px;
    font-size: 12px;
}
.Apparel-casi .cart-list .product-name-wrap .cart-remove-item a {
    text-decoration: underline;
    font-size: 12px;
    font-weight: 600;
    color: #212529;
    display: block;
	line-height: 1;
}
.Apparel-casi .show-discount-text {
	margin-top: 10px;
    font-size: 12px;
    color: #ffa500;
    font-weight: 600;
}
/**/
.Apparel-casi .cart-list .cart-product-details .cart-product-price {
	margin-bottom: 10px;
	display: flex;
    justify-content: flex-end;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-price .label-price {
	margin-right: 10px;
    color: #b0b0b0;
	font-size: 12px;
    text-transform: uppercase;
	line-height: 1;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-price .price-wrap {
	font-weight: 600;
    font-size: 14px;
	line-height: 1;
}
/**/
.Apparel-casi .cart-list .cart-product-details .cart-product-quantity {
	display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-quantity .line-qty {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-quantity .line-qty .label-qty {
	margin-right: 10px;
    color: #b0b0b0;
    font-size: 12px;
    text-transform: uppercase;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-quantity .line-promo {
	display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-quantity .line-promo .label-promo-code {
	margin-right: 10px;
    color: #b0b0b0;
    font-size: 12px;
    text-transform: uppercase;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-quantity .line-promo .promo-wrapper {
	display: flex;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-quantity .line-promo .promo-wrapper .promo-code input {
	height: 30px;
    max-width: 100px;
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    letter-spacing: 3px;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-quantity .line-promo .promo-wrapper .promo-updated button {
	font-size: 14px;
    color: #212529;
    padding: 5px 10px;
    background-color: #d9d9d9;
    border: 0;
	height: 30px;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-total {
	display: flex;
    justify-content: flex-end;
	align-items: center;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-total .label-total {
	font-size: 12px;
    text-transform: uppercase;
    color: #b0b0b0;
    margin-right: 10px;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-total .total-wrap {
	display:flex;
	justify-content: flex-end;
	font-size: 14px;
	font-weight: 600;
}
/* remove button/input border on Focus */
.Apparel-casi .qty-no input:focus, 
.Apparel-casi .promo-code input:focus,
.Apparel-casi .promo-updated button:focus {outline: none;}
/* delivery option selection */
.Apparel-casi .cart-details .top-total-hr {
	border-color: #eee;
	margin-top: 0;
	margin-bottom: 0;
}
.Apparel-casi .cart-details .align-option {
	display: flex;
    justify-content: flex-end;
	align-items: center;
}
.Apparel-casi .cart-details .del-label {padding: 15px 10px;}


.Apparel-casi .cart-details .cart-sub-total .st-item {
	min-width: 165px;
    padding: 10px;
    border: 1px solid #eee;
}
.Apparel-casi .mr10 {margin-right: 10px;}
.Apparel-casi .mr20 {margin-right: 20px;}

.Apparel-casi .cart-details .st-item {font-weight: 600;}
.Apparel-casi .cart-details .total-to-pay .st-item {font-size: 16px;}

.Apparel-casi .cart-details .cart-button-wrapper {
	margin-top: 30px;
	display:flex;
	flex-direction: column;
}
.Apparel-casi .cart-details .cart-button-wrapper a:first-child {margin-bottom: 15px;}
/* Login Page ---------------------------------------------------------------------- */
.Apparel-casi .login {padding-top: 20px;}
.Apparel-casi .login .login-tabs {
	border: 1px solid #eee;
	padding: 20px;
	box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
	margin-bottom: 20px;
}
.Apparel-casi .login .nav-tabs {border-bottom: 0;margin-bottom: 20px;}
.Apparel-casi .login .nav-tabs .nav-item.show .nav-link, 
.Apparel-casi .login .nav-tabs .nav-link.active {border: 0;}
.Apparel-casi .login .login-tabs .nav-item {width: 50%;}
.Apparel-casi .login .btn-login {
	width: 100%;
    display: block;
	margin-top: 30px;
}
.Apparel-casi .login .forgot-password {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 15px;
}
.Apparel-casi .login label {margin-bottom: 5px;font-size: 12px;}
.Apparel-casi .login .forgot-password a {font-size: 12px;color: #212529;}
.Apparel-casi .login .nav-tabs .nav-link {border: 0;}
.Apparel-casi .login .nav-tabs .nav-item a.nav-link {
	text-align: center;
	text-transform: uppercase;
	color: #212529;
	font-size: 20px;
	font-weight: 600;
}
.Apparel-casi .login .nav-tabs .nav-item .nav-link.active { 
	border-bottom: 3px solid #ce9d37;
	color: #ce9d37;
}
.Apparel-casi .login .link-privacy-policy {
	text-decoration: underline;
    font-size: 12px;
    font-weight: 600;
    color: #212529;
}
.Apparel-casi .login .signup-benefits p {margin-bottom: 20px;}
.Apparel-casi .login .signup-benefits .text-header {font-size: 18px; font-weight: 600;}
.Apparel-casi .login .signup-benefits .signup-item {display: flex;margin-bottom: 20px;}
.Apparel-casi .login .signup-benefits .signup-item i {font-size: 22px;margin-right: 5px;min-width: 30px;color:#ce9d37;}
.Apparel-casi .login .signup-benefits .signup-item p {font-size: 14px;margin-bottom: 0;text-transform: uppercase;}
/* Cart Top ------------------------------------------------------------------------ */
.Apparel-casi .cart-top {
	width: 300px;
	padding: 10px;
}
.Apparel-casi .cart-top .cart-top-item {
	display: flex;
	display: flex;
    margin-bottom: 10px;
    border-bottom: 1px solid #e8e8e8;
}
.Apparel-casi .cart-top .cart-top-product-img {margin-right: 5px;}
.Apparel-casi .cart-top .cart-top-product-img img {width: 50px;}
.Apparel-casi .cart-top .cart-top-prdocut-details a {
	padding: 0 !important;
}
.Apparel-casi .cart-top .cart-top-prdocut-qty-price span {
	color: #8d6717;
	font-size: 14px;
}
.Apparel-casi .cart-top .sub-total {
	display: flex;
	justify-content: space-between;
}
.Apparel-casi .cart-top .total-topay {
	display: flex;
	justify-content: space-between;
}
.Apparel-casi .cart-top .cart-top-total .sub-total {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 10px;
}
.Apparel-casi .cart-top .cart-top-total .total-topay {
	font-size: 16px;
	font-weight: 600;
}
.Apparel-casi .cart-top .cart-top-buttons {
	margin-top: 15px;
}
.Apparel-casi .cart-top .cart-top-buttons a {
	display: flex;
	justify-content: center;
}
.Apparel-casi .cart-top .cart-top-buttons a {color: #ffffff !important;}
.Apparel-casi .cart-top .cart-top-buttons a:hover {background-color: #ce9d37 !important;}
/* Register Verify ----------------------------------------------------------------- */
.Apparel-casi .register-verify {padding-top: 20px;}
.Apparel-casi .register-verify .list-group {margin-bottom: 20px;}
.Apparel-casi .register-verify .list-group-item {
	display: flex;
    align-items: center;
}
.Apparel-casi .register-verify .list-group-item i {
	font-size: 22px;
    margin-right: 5px;
    min-width: 30px;
    color: #ce9d37;
}
.Apparel-casi .register-verify .list-group-item a {
	font-size: 16px;
	font-weight: 500;
	text-decoration: none;
	color: #212529;
}
.Apparel-casi .register-verify .register-text .text-header {
	font-size: 18px;
    font-weight: 600;
	margin-bottom: 20px;
}
.Apparel-casi .register-verify .btn-verify {
	width: 100%;
    display: block;
    margin-top: 30px;
}
/* Create Account ------------------------------------------------------------------ */
.Apparel-casi .create-account {padding-top: 20px;}
.Apparel-casi .create-account h4 {
	text-align: center;
    margin-bottom: 20px
}
.Apparel-casi .create-account h6 {
    margin-bottom: 15px;
}
.Apparel-casi .create-account .btn-comp-reg {
	width: 100%;
    display: block;
	margin: auto;
}
/* Page - Read Our Story ----------------------------------------------------------- */
/* .our-story {padding-top: 20px;} */
.Apparel-casi .our-story h4 {
	padding-top: 20px;
	text-align: center;
    margin-bottom: 20px
}
/* Page - Bridal Service ----------------------------------------------------------- */
/* .our-story {padding-top: 20px;} */
.Apparel-casi .bridal-service h4 {
	padding-top: 20px;
	text-align: center;
    margin-bottom: 20px
}
/* Page - Weaving Process ---------------------------------------------------------- */
.Apparel-casi .weaving-process-in h4 {
	padding-top: 20px;
	text-align: center;
    margin-bottom: 20px
}
.Apparel-casi .mb20 {margin-bottom: 20px;}
/* Page - Testimonials ------------------------------------------------------------- */
.Apparel-casi .testimonials-in h4 {
	padding-top: 20px;
	text-align: center;
    margin-bottom: 20px
}
.Apparel-casi .testimonials-in .testimonials-item {background-color: #ffffff;margin-bottom: 20px;}
.Apparel-casi .testimonials-in .testimonials-item-inner {
	border:1px solid #e8e8e8;
	border-radius: 8px;
	padding: 10px;
	display: flex;
	flex-direction: column;
}
.Apparel-casi .testimonials-in .testimonials-user {
	display: flex;
}
.Apparel-casi .testimonials-in .testimonials-item img  {
	border-radius: 50%;
	width: 50px;
}
.Apparel-casi .testimonials-in .user-name {font-weight: 600;font-size: 14px;}
.Apparel-casi .testimonials-in .user-rating { padding: 0 10px;}
.Apparel-casi .testimonials-in .user-rating .rating-starts {
 color: #FDCC0D;
 font-size: 10px;
}
.Apparel-casi .testimonials-in .testimonials-text {
	padding: 10px 0;
	margin-bottom: 0;
	color: #818181;
	margin-top: 10px;
	border-top: 1px solid #e8e8e8;
}
.Apparel-casi .testimonials-in .btn-view {
	display: flex;
    justify-content: center;
}
/* Page - Blogs ------------------------------------------------------------- */
.Apparel-casi .blogs h4 {
	padding-top: 20px;
	text-align: center;
    margin-bottom: 20px
}
.Apparel-casi .blogs .blog-tabs {
	border: 1px solid #eee;
	padding: 20px;
	box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
	margin-bottom: 20px;
}
.Apparel-casi .blogs .nav-tabs {border-bottom: 0;margin-bottom: 20px;}
.Apparel-casi .blogs .nav-tabs .nav-item.show .nav-link, 
.Apparel-casi .blogs .nav-tabs .nav-link.active {border: 0;}
.Apparel-casi .blogs .nav-tabs .nav-link {border: 0;}
.Apparel-casi .blogs .nav-tabs .nav-item a.nav-link {
	text-align: center;
	text-transform: uppercase;
	color: #212529;
	font-size: 16px;
	font-weight: 600;
}
.Apparel-casi .blogs .nav-tabs .nav-item .nav-link.active { 
	border-bottom: 3px solid #ce9d37;
	color: #ce9d37;
}

.Apparel-casi .blogs .blog-items {
	display: flex;
	flex-direction: column;
	padding-top: 15px;
	margin-top: 15px;
	border-top: 1px solid #e8e8e8;
}
.Apparel-casi .blogs .blog-items:first-child {border-top: 0;padding-top: 0;}
.Apparel-casi .blogs .blog-items .blog-items-header {margin-bottom: 10px;}
.Apparel-casi .blogs .blog-items .blog-items-header a {
	font-size: 16px;
	font-weight: 500;
	color: #212529;
	text-decoration: none;
}

.Apparel-casi .blogs .blog-items .blog-items-details {margin-top: 10px;}
.Apparel-casi .blogs .blog-items img {width: 100%;}
/* Page - Blogs ------------------------------------------------------------- */
.Apparel-casi .quality-faq h4 {
	padding-top: 20px;
	text-align: center;
    margin-bottom: 20px
}
/* Contact Page ------------------------------------------------------------- */
.Apparel-casi .contact h4 {
	padding-top: 20px;
	text-align: center;
    margin-bottom: 20px
}
.Apparel-casi .contact .btn-submit-wrapper {
	display: flex;
    justify-content: center;
}
.Apparel-casi .contact .btn-submit-wrapper a {
	/* width: 300px; */
	width: 100%;
}
/* Gallery Page ------------------------------------------------------------- */
.Apparel-casi .gallery h4 {
	padding-top: 20px;
	text-align: center;
    margin-bottom: 20px
}
/* ============================================================================================================= */
/* ================================================================================= */
/* Media Queries =================================================================== */
/* ================================================================================= */
@media (min-width: 576px) {
	.Apparel-casi .our-collections-mobile .item-text p {
		min-height: 50px;
		max-height: 50px;
	}
	/* filters */
	.Apparel-casi .prd-filter-area .filter-inner .filter-top {
		flex-direction: row;
		justify-content: space-between;
	}
	.Apparel-casi .prd-filter-area .filter-inner .filter-bottom {flex-direction: row;}
	.Apparel-casi .prd-filter-area .filter-inner .filter-bottom .filter-colour {min-height: auto;}
	.Apparel-casi .slider {width: 210px;}
	/* Cart */
	.Apparel-casi .cart-items {
		display: flex;
		justify-content: space-between;
	}
	.Apparel-casi .cart-details .cart-button-wrapper {align-items: flex-end;}
	.Apparel-casi .cart-details .cart-button-wrapper a {min-width: 300px;}
	/* Contact Submit Button */
	.Apparel-casi .contact .btn-submit-wrapper a {
		width: 300px;
	}
}
/* ================================================================================= */
@media (min-width: 768px) {
	/* other styles */
	/* ------------ */
	.Apparel-casi .logo img {height: 100px;}
	.Apparel-casi .mobile-menu {margin-left: 15px;}
	.Apparel-casi .btn-search {display:none;}
	.Apparel-casi .search-control input {width: 300px;}
	.Apparel-casi .search-control {display:block;}
	.Apparel-casi .search-control {
		z-index: unset;
		position: unset;
		left: unset;
		top: unset;
		height: unset;
		width: unset;
		padding: 0 10px;
	}
	.Apparel-casi .appointment h4 {font-size: 24px;}
	/* product listing page */
	.Apparel-casi .prd-hero .prd-hero-header {padding: 15px;	}
	.Apparel-casi .prd-hero .prd-hero-header h4 {font-size: 1.5rem;}
	/* Cart */
	.Apparel-casi .cart-details .show-cart-count, .cart-list .cart-count-number {display: none;}
	.Apparel-casi .label-price, .label-qty, .label-total {display: none;}
	.Apparel-casi .cart-list .cart-product-details .cart-product-quantity .line-promo {
		flex-direction: column;
    	align-items: baseline;
		margin-bottom: 0;
	}
	.Apparel-casi .cart-list .cart-product-details .cart-product-quantity .line-promo .label-promo-code {
		margin-bottom: 3px;
	}
	.Apparel-casi .cart-details .header-wrapper {
		display:flex;
		width: 100%;
		height: auto;
		margin-bottom: 5px;
	}
	.Apparel-casi .cart-list .cart-product-name {margin-bottom: 0;}
	.Apparel-casi .cart-header-left {width: 40%;}
	.Apparel-casi .cart-header-right {width: 60%;display: flex;}
	.Apparel-casi .cart-header-right .div1 {width: 40%; text-align: left;}
	.Apparel-casi .cart-header-right .div2 {width: 25%; text-align: right;}
	.Apparel-casi .cart-header-right .div3 {width: 50%; text-align: right;}
	.Apparel-casi .cart-header-right .div4 {width: 25%; text-align: right;}
	.Apparel-casi .cart-header-left span, .cart-header-right span {
		font-size: 14px;
    	font-weight: 600;
	}
	.Apparel-casi .cart-product-details {width: 60%;display: flex;}
	.Apparel-casi .cart-product-name {width: 40%;}
	.Apparel-casi .cart-list .cart-product-details .cart-product-price {width: 25%;}
	.Apparel-casi .cart-list .cart-product-details .cart-product-quantity {width: 50%;flex-direction: column;}
	.Apparel-casi .cart-list .cart-product-details .cart-product-total {width: 25%;align-items: baseline;}
	/* Create Account */
	.Apparel-casi .create-account {padding: 40px;}
	.Apparel-casi .create-account p {padding: 30px 0; margin-bottom: 0;}
	.Apparel-casi .create-account .btn-comp-reg {width: 400px;}
	/* Blog */
	.Apparel-casi .blogs .blog-items-inner-wrapper {display: flex;}
	.Apparel-casi .blogs .blog-items img {width: auto;}
	.Apparel-casi .blogs .blog-items .blog-items-details {margin-top: 0;margin-left: 15px;}
	.Apparel-casi .blogs .blog-items .blog-items-header a {font-size: 20px;}
	/* Contact */
	.Apparel-casi .contact {max-width: 900px;margin: auto;}

}
/* ================================================================================= */
@media (min-width: 992px) {
	/* Our Collections Mobile ------------------------------------------------------ */
	.Apparel-casi .our-collections-mobile {display: none !important;}
	/* Our Collections Large ------------------------------------------------------- */
	.Apparel-casi .our-collections {display: block !important;margin-top: 20px;}
	/* other styles */
	/* ------------ */
	.Apparel-casi .logo img {height: 160px;}
	.Apparel-casi .bg-round {width: 40px;height: 40px;	}
	.Apparel-casi .header-right ul a .favourite-items i, 
	.Apparel-casi .header-right ul a .shopping-cart-items i, 
	.Apparel-casi .header-right ul a .user-icon i {
		font-size: 24px;
	}
	.Apparel-casi .count {
		bottom: -5px;
		font-size: 12px;
		width: 20px;
    	height: 20px;
    	line-height: 20px;
	}
	.Apparel-casi .welcome-message, .our-collections, .promotions, .appointment, .testimonials
	{margin-top: 40px;}
	.Apparel-casi .our-collections h4, .promotions h4, .testimonials h4
	{margin-bottom: 20px; font-size: 32px;}
	.Apparel-casi .welcome-message h4 {font-size: 32px;}
	.Apparel-casi .welcome-message .message-wrapper p {padding: 0 60px 20px;}
	.Apparel-casi .search-control input {width: 450px;}
    .Apparel-casi .profile-name {
		display:block;
		color: #ce9d37;
		font-weight: 500;
	}
	.Apparel-casi .mobile-menu .opt-menu {display:none;}
	.Apparel-casi .mobile-nav-menu .site-overlay.top-nav {display: none;}
	.Apparel-casi .top-navbar-wrapper {
		display: block;
		position: relative;
		width: 100%;
		left:0;
		color: #ffffff;
	}
	.Apparel-casi .top-navbar {justify-content: flex-end;}   
	.Apparel-casi .top-navbar .top-nav {
		margin:0;
		padding:0;
		list-style: none;
		display: flex;
	    height: 100%;
	    align-items: center;
	    overflow: inherit;
		width: auto;
	}
	.Apparel-casi .top-navbar .top-nav li {
		list-style: none;
		margin:0;
		display: flex;
	    height: 100%;
	    align-items: center;
	    position: relative;
	}
	.Apparel-casi .top-navbar .top-nav li a {
		height: 100%;
	    line-height: 50px;
	    padding:0 15px;
	    text-decoration: none;
	}
	.Apparel-casi .top-navbar .dd-list .ddl-menu {position: absolute;}
	.Apparel-casi .top-navbar ul.top-nav li.dd-list::after {top: auto;-webkit-transform: inherit;transform: inherit;}
	.Apparel-casi .top-navbar .dd-list ul.ddl-menu li a {padding: 5px 15px;}
	/* Testimonials */
	.Apparel-casi .testimonials .testimonials-item img  {width: 75px;}
	.Apparel-casi .testimonials .user-name {font-size: 16px;}
	.Apparel-casi .testimonials p {font-size: 14px;}
	/* product listing page */
	.Apparel-casi .prd-list-items {padding: 40px 40px 0;}
	.Apparel-casi .prd-filter-area {padding: 20px 40px 0;}
	.Apparel-casi .prd-hero .prd-hero-header {padding: 20px;	}
	.Apparel-casi .prd-hero .prd-hero-header h4 {font-size: 2.5rem;}
	.Apparel-casi .prd-pagination {padding: 20px 40px 0;}
	/* products details */
	.breadcrumb-container {padding: 40px 40px 0;}
	.Apparel-casi .product-details {padding: 20px 40px 0;}
	.Apparel-casi .product-details .prd-details-img-wrapper {flex-direction: row;justify-content: flex-end;}
	.Apparel-casi .product-details .prd-details-img-wrapper .prd-img-tbn {flex-direction: column;margin-top: 0;}
	.Apparel-casi .product-details .prd-details-img-wrapper .prd-img-tbn span {margin-bottom: 5px;}
	.Apparel-casi .product-details .prd-details-img-wrapper .prd-img-tbn span img {max-width: 75px;}
	.Apparel-casi .product-details .commercial-details {margin-top: 50px;}
	/* Cart */
	.Apparel-casi .cart-details {padding: 40px 50px 0;}
	/* Login Page */
	.Apparel-casi .login {padding: 80px 40px 80px;}
	.Apparel-casi .login .login-tabs-wrapper {display: flex;justify-content: flex-end;}
	.Apparel-casi .login .login-tabs {width: 400px;}
	.Apparel-casi .login .signup-benefits {width: 400px;margin-left: 20px;}
	/* Register Verify */
	.Apparel-casi .register-verify {padding: 80px 40px 80px;}
	.Apparel-casi .register-verify .register-details-wrapper {display: flex;justify-content: flex-end;}
	.Apparel-casi .register-verify .register-details-wrapper .list-group {width: 400px;}
	.Apparel-casi .register-verify .register-text {width: 400px;margin-left: 20px;}
	/* Create Account */
	.Apparel-casi .create-account {padding: 40px 200px 0;}
	/* Inner Pages */
	.Apparel-casi .page-inner {padding: 40px 50px 0;}
}
/* ================================================================================= */
@media (min-width: 1200px) {
	.Apparel-casi .search-control input {width: 600px !important;}
	/* Cart */
	.Apparel-casi .cart-details {padding: 40px 100px 0;}
}
/* ================================================================================= */



/* Google Font - Poppins */
/* Google Font - Roboto */
 

/* Body */
/* ---------------------------------------------------------------------- */
.Spiceway .body {
  /* font-family: "Poppins","Helvetica Neue",Helvetica,Arial,sans-serif; */
  color: #303030;
  background-color: #ffffff;
  font-size: 16px;
}
/* Header */
/* ---------------------------------------------------------------------- */
.Spiceway .header-top {
  display: flex; 
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  background-color: #1B2761;
}
.Spiceway .header-top a.login-wrapper {
  display: flex;
  color: #FBB005;
  font-size: 14px;
}
.Spiceway .header-top a.login-wrapper:hover {
  text-decoration: none;
  color: rgba(251, 176, 5, 0.80);
}
.Spiceway .mlr5 {margin-left: 5px; margin-right: 5px;}
.Spiceway .header-top a.shopping-cart {
  font-size: 16px;
  width: 30px;
  height: 30px;
  color: #FBB005;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Spiceway .header-top a.shopping-cart:hover {
  text-decoration: none;
  color: rgba(251, 176, 5, 0.80);
}
.Spiceway .header-top a.shopping-cart .cart-count {
  position: absolute;
  right: -10px;
  bottom: -4px;
  font-size: 10px;
  font-weight: 700;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  background-color: #f60303;
  border: 2px solid #1B2761;
}
.Spiceway .navbar-nav.m-app-nav {margin-top: 20px;}
.Spiceway .navbar-brand .brand-logo {height: 30px;}
.Spiceway .navbar-toggler {
  border: 0;
  font-size: 24px;
}
.Spiceway .navbar-light .navbar-toggler i.fa-bars {
  color: #0E98E1;
}
.Spiceway .navbar-light .navbar-toggler i.fa-times {
  color: #FBB005;
}
.Spiceway .navbar-brand {margin-right: 40px;}
.Spiceway .navbar-light .navbar-nav .nav-link {
  color:#0E98E1;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 8px 16px 16px 0;
}
/* .Spiceway .navbar-light .navbar-nav .nav-link:hover {text-decoration: none;color: #FBB005;} */
.Spiceway .navbar-light .navbar-nav .nav-link.active {color: #FBB005;}
.Spiceway .navbar-light .navbar-nav .nav-link:active, .Spiceway .navbar-light .navbar-nav .nav-link:focus {color: #FBB005;}
/* Hero Banner */
/* ---------------------------------------------------------------------- */
.Spiceway .hero-banner  {
  background-image: linear-gradient(
  rgba(0,0,0,0.0), 
  rgba(0,0,0, 0.35)), 
  url(/static/media/seafood-hero.fa46bebb.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.Spiceway .hero-banner h1, h3 {
  color: #ffffff;
  text-align: center;
}
.Spiceway .hero-banner h1 {
  font-weight: 700; 
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: 2.5rem;
  letter-spacing: 3px;
  word-spacing: 10px;
  text-shadow: -1px -1px 6px rgb(239 151 10 / 84%);
}
.Spiceway .hero-banner h3 {
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  text-transform: none;
}
.Spiceway .hero-text-wrapper {
  background-color: rgba(0,0,0, 0.35);
  padding: 2rem;
  margin: 2rem;
  border-radius: 8px;
}
.Spiceway .hero-banner.inner {
  height: 40vh;
}
.Spiceway .hero-banner.inner .hero-text-wrapper {
  padding: 0;
  margin: 1rem;
  background-color: transparent;
}
.Spiceway .hero-banner.inner h3 {
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 15px;
}
.Spiceway .hero-banner.inner h6 {
  color: #ffffff;
  text-align: justify;
}
/* Product Categories */
/* ---------------------------------------------------------------------- */ 
.Spiceway .section {
  padding-top: 30px;
  background-color: #ffffff;
}
.Spiceway .header-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}
.Spiceway h3 {
  color: #1B2761;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  word-spacing: 3px;
}
.Spiceway .bottom-hr {
  display: inline-block;
  width: 80px;
  height: 4px;
  border-radius: 4px;
  background-color: #FBB005;
}
.Spiceway .item {
  padding: 0;
  display: inline-block;
  position: relative;
}
.Spiceway .item img {
  max-height: 400px;
  width: auto;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 5px;
}
.Spiceway .item .item-text {
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.50);
  width: 100%;
}
.Spiceway .item .item-text h5 {
  padding: 16px 8px;
  color: #ffffff;
  margin-bottom: 0;
}
/* Product Carousel Styles */
.Spiceway .section .owl-theme .owl-dots .owl-dot.active span, .Spiceway .section .owl-theme .owl-dots .owl-dot:hover span {
  background: #FBB005;
}
.Spiceway .owl-theme .owl-nav [class*='owl-']:hover {
  background: #FBB005 !important;
}
.Spiceway .owl-nav {display: none;}
.Spiceway .owl-dots {margin-top: 10px;}
/* Brands */
/* ---------------------------------------------------------------------- */ 
.Spiceway .brands .item {
  padding: 20px; 
  margin: 0 10px;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  border: 1px solid rgba(234, 237, 237, 0.70);
}
.Spiceway .brands .item img {box-shadow: none;}
/* Featured Products */
/* ---------------------------------------------------------------------- */
.Spiceway .featured-prd .item {
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  margin: 10px 20px;
  padding: 20px;
  min-width: 300px;
  min-height: 300px;
}
.Spiceway .featured-prd .item .item-text {
  position: unset;
  background-color: #ffffff;
}
.Spiceway .featured-prd .item .item-text h5 {
  font-size: 16px; 
  color: #303030;
  font-weight: 500;
}
.Spiceway .featured-prd .item img {
  height: 270px; 
  width: auto;
  box-shadow: none;
}
.Spiceway .featured-prd a.item:hover {
  text-decoration: none;
}
/* Deals */
/* ---------------------------------------------------------------------- */
.Spiceway .section.deals {
  background-color: #F3F3F4;
  padding-bottom: 60px;
}
.Spiceway .section.deals .item img {
  box-shadow: none;
  border-radius: 12px;
  border: 3px solid #0E98E1;
}
.Spiceway .section.deals .item img:hover {
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  border-color: #FBB005;
}
/* Intro */
/* ---------------------------------------------------------------------- */
.Spiceway .intro  {
  background-image: linear-gradient(
  rgba(0,0,0,0.4), 
  rgba(0,0,0, 0.7)), 
  url(/static/media/hp-intro-bg.5c43590a.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 33rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}
.Spiceway .section.intro .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Spiceway .intro h3 {
  color: #ffffff;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 700; 
  font-size: 2rem;
  line-height: 2.5rem;;
  text-transform: none;
  margin-bottom: 30px;
}
.Spiceway .learn-more {
  background-color: #0E98E1;
  color: #ffffff;
  min-width: 200px;
  border-radius: 4px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Spiceway .learn-more h5 {
  margin-bottom: 0;
  text-transform: uppercase;
  margin-right: 15px;
}
.Spiceway .learn-more  i {
  font-size: 20px;
  color: #1B2761;
}
.Spiceway .learn-more:hover, .Spiceway .learn-more:focus, .Spiceway .learn-more:active {
  text-decoration: none;
  color: #ffffff;
  box-shadow: 0 3px 25px rgb(198 226 240 / 30%);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}
/* Footer */
/* ---------------------------------------------------------------------- */
.Spiceway .section.footer {
  margin-top: 60px;
  padding: 60px 0 60px 0;
  background-color: #1B2761;
}
.Spiceway .section.footer .container {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
}
.Spiceway .section.footer .footer-address {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
.Spiceway .section.footer .brand-logo {margin-bottom: 10px;}
.Spiceway .section.footer .brand-logo img {
  height: 60px;
  width: auto;
}
.Spiceway .section.footer .biz-name {
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.Spiceway .biz-add-line {
  list-style: none;
  padding-left: 0;
  margin-bottom: 10px;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
}
.Spiceway .section.footer .biz-phone {
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 0;
  text-align: center;
}
.Spiceway .section.footer .footer-social {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.Spiceway .section.footer .social-link {margin-bottom: 30px;}
.Spiceway .section.footer .social-link a {
  font-size: 24px;
  color: #ffffff;
  margin-left: 15px;
}
.Spiceway .section.footer .social-link a:hover {
  color: rgba(255, 255, 255, 0.75);
}

.Spiceway .section.footer .policy ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.Spiceway .section.footer .policy ul li {
  display: inline-block;
  padding-left: 10px;
}
.Spiceway .section.footer .policy ul li a {
  color: #ffffff;
  font-size: 12px;
  text-decoration: none;
}
.Spiceway .section.footer .policy ul li a:hover {
  color: #FBB005;
}
.Spiceway .section.footer .policy ul li:first-child {
  padding-left: 0;
  padding-right: 10px;
  border-right: 1px solid #ffffff;
}
/* Product Page */
/* ---------------------------------------------------------------------- */
.Spiceway .hero-banner.inner.products {
  background-image: linear-gradient(
    rgba(0,0,0,0.0), 
    rgba(0,0,0, 0.45)), 
    url(/static/media/products-bg.d5cd6d58.png);
}
.Spiceway .products .search button {
  font-size: 14px;
  padding: 4px 8px;
  border: 0;
  border-radius: 0;
  color: #ffffff;
  background-color: #1B2761;
}
.Spiceway .products .search button:active, 
.Spiceway .products .search button:focus {
  color: #ffffff !important;
  background-color: #1B2761 !important;
  box-shadow: none !important;
}
.Spiceway .products .search  input {
  font-size: 14px;
  padding: 4px 8px;
  height: 38px;
  border: 0;
  background-color: rgba(243, 243, 243, 0.75)
}
.Spiceway .products .menu-prd-cat button, .Spiceway .products .menu-prd-cat .dropdown-menu {width: 100%;}
.Spiceway .products .input-group-append button {
  background-color: #FBB005 !important;
}
.Spiceway .products .input-group-append button:active, 
.Spiceway .products .input-group-append button:focus {
  color: #ffffff !important;
  background-color: #FBB005 !important;
  box-shadow: none !important;
}
.Spiceway .products .list-group-item.active {
  background-color: #1B2761;
  color: #FBB005;
}
.Spiceway .prd-cat-title {margin-top:20px; margin-bottom: 0;}
.Spiceway .mt20 {margin-top: 20px;}
.Spiceway .mb0 {margin-bottom: 0;}
.Spiceway .prd-item {
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  padding: 10px;
  margin-top: 30px;
  text-align: center;
}
.Spiceway .prd-item .prd-item-link {
  text-decoration: none;
  display: inline-block;
}
.Spiceway .prd-item .prd-item-text h5 {
  font-size: 16px;
  color: #303030;
  margin-bottom: 20px;
}
.Spiceway .prd-item .prd-item-text h6 {
  padding: 8px 16px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #FBB005;
  background-color: #ffffff;
  border: 3px solid #FBB005;
  border-radius: 50px;
  display: inline-block;
  min-width: 100px;  
}
.Spiceway .prd-count-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.Spiceway .prd-count-inner button {
  font-size: 12px;
  color: #212529;
  padding: 5px 10px;
  background-color: #F3F3F4;
  border: 0;
  width: 40px;
  height: 30px;
}
.Spiceway .prd-count-inner .qty-no input {
  width: 50px;
  height: 30px;
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}
.Spiceway .prd-count-inner button.add-btn {
  font-size: 14px;
  font-weight: 600;
  color: #FBB005;
  padding: 5px 8px;
  background-color: #1B2761;
  border: 0;
  width: auto;
  height: 30px;
  text-transform: uppercase;
}
.Spiceway .prd-count-inner .out-of-stock {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #c4c1c1;
  margin-right: 15px;
}
/* pagination */
.Spiceway .pagination {margin-top: 50px;float: right;}
.Spiceway .page-link {
  color: #0E98E1;
}
.Spiceway .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0E98E1;
  border-color: #0E98E1;
}
/* Contact Us */
/* ---------------------------------------------------------------------- */
.Spiceway .hero-banner.inner.contactus {
  background-image: linear-gradient(
    rgba(0,0,0,0.0), 
    rgba(0,0,0, 0.65)), 
    url(/static/media/contactus-img.cd372597.jpg);
}
.Spiceway .section.contactus .biz-address {
  padding: 20px;
  background-color: #F3F3F4;
  margin-bottom: 30px;
}
.Spiceway .section.contactus .biz-name {
  text-transform: uppercase;
  font-weight: 600; 
  margin-bottom: 15px;
}
.Spiceway .hero-banner.inner.contactus h3 {margin-bottom: 0;}
.Spiceway .section.contactus .biz-add-line {
  color: #303030;
  text-align: left;
  font-size: 16px;
}
.Spiceway .mob-order {order: -1;}
.Spiceway .section.contactus .contact-top .notes {
  font-size: 14px;
  color: #c4c1c1;
}
.Spiceway .section.contactus .contact-top .notes span {
  font-weight: 700;
  margin-right: 5px;
  color: #ff1200;
}
.Spiceway .required::after {
  content: " *";
  color: #ff1200;
}
.Spiceway .section.contactus .form-group {margin-top: 16px;}
.Spiceway .section.contactus label {font-weight: 500;}
.Spiceway .section.contactus input {
  background-color: #F3F3F4;
  border: 0;
  border-radius: 0;
  padding: 24px;
}
.Spiceway .section.contactus textarea {
  background-color: #F3F3F4;
  border: 0;
  border-radius: 0;
  padding: 24px;
  display: block;
  width: 100%;
}
.Spiceway .form-btn-wrapper {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 60px;
}
.Spiceway .form-btn-wrapper button {
  text-transform: uppercase;
  padding: 10px 20px;
  border: 0;
  box-shadow: none;
  background-color: #0E98E1;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  min-width: 200px;
  border-radius: 4px;
}
.Spiceway .form-btn-wrapper button:hover, 
.Spiceway .form-btn-wrapper button:focus, 
.Spiceway .form-btn-wrapper button:active {
  box-shadow: 0 3px 25px rgb(92 92 92 / 35%);
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}
.Spiceway .section.aboutus .form-btn-wrapper button:hover, 
.Spiceway .section.aboutus .form-btn-wrapper button:focus, 
.Spiceway .section.aboutus .form-btn-wrapper button:active {
  box-shadow: 0 3px 25px rgb(198 226 240 / 30%);
}
.Spiceway .container.map {
  margin-top: 40px;
}
.Spiceway .container.map .map-text {
  text-transform: uppercase;
  margin-bottom: 20px;
}
/* Map */
.Spiceway .map-responsive {
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}
.Spiceway .map-responsive iframe {
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}
/* About Us */
/* ---------------------------------------------------------------------- */
.Spiceway .hero-banner.inner.aboutus {
  background-image: linear-gradient(
    rgba(0,0,0,0.03), 
    rgba(0,0,0, 0.55)), 
    url(/static/media/about-us-top.9adedc1c.jpg);
}
.Spiceway .hero-banner.inner.aboutus h3 {margin-bottom: 0;}
.Spiceway .section.aboutus .content-wrapper {
  margin-bottom: 30px;
}
.Spiceway .section.aboutus .content-wrapper .cont-cover {
  background-color: #1B2761;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
  color: #ffffff;
}
.Spiceway .section.aboutus .content-wrapper .cont-cover h4 {
  text-transform: uppercase;
  color: #FBB005;
  font-weight: 500;
  margin-bottom: 20px;
}
.Spiceway .section.aboutus .content-wrapper .cont-cover p { margin-bottom: 0; }
.Spiceway .section.aboutus .form-btn-wrapper {margin-bottom: 0;}
/* Responsive Media Query */
/* ====================================================================== */

/* @media (min-width: 768px) */
@media (min-width: 768px) {
  /* Header */
  /* ---------------------------------------------------------------------- */
  .Spiceway .navbar-nav.m-app-nav {margin-top: 0;}
  .Spiceway .header-top a.shopping-cart {
    font-size: 14px;
    width: 40px;
    height: 40px;
    border: 2px solid #FBB005;
    border-radius: 50%;
  }
  /* Hero */
  .Spiceway .hero-banner  {height: 85vh;}
  .Spiceway .hero-banner h1 {font-size: 3.5rem;}
  /* Inner Hero Product*/
  .Spiceway .hero-banner.inner  {
    min-height: 400px;
    padding: 20px;
    /* justify-content: flex-start; */
  }
  .Spiceway .hero-banner.inner h3 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 3rem;
  }
  .Spiceway .hero-banner.inner .hero-text-wrapper {
    padding: 4rem;
  }
  .Spiceway .hero-banner.inner h6 {
    color: #ffffff;
    text-align: justify;
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
  /* Product Carousel Styles */
  .Spiceway .section {padding-top: 60px;}
  .Spiceway .owl-nav {display: block;}
  /* Intro */
  .Spiceway .intro  {height: 38rem;}
  .Spiceway .intro h3 { 
    font-size: 2.5rem;
    line-height: 4.5rem;;
    word-spacing: 5px;
    text-transform: none;
    margin-bottom: 50px;
  }
  /* Footer */
  .Spiceway .section.footer .container {
    flex-direction: row;
    justify-content: space-between;
    align-items: unset;
  }
  .Spiceway .section.footer .footer-address {
    flex-direction: row;
    align-items: unset;
    margin-bottom: 0;
  }
  .Spiceway  .section.footer .brand-logo {
    margin-bottom: 0;
    margin-right: 10px;
  }
  .Spiceway .section.footer .biz-add-line,  
  .Spiceway .section.footer .biz-phone {
    text-align: left;
  }
  .Spiceway .section.footer .footer-social {
    align-items: flex-end;
  }
  /* Product Page */
  .Spiceway .products .search {margin-bottom: 40px;}
  .Spiceway .input-group-append {font-size: 16px;}
  .Spiceway .prd-cat-title {margin-top: 0;}
  /* Contact Us */
  .Spiceway .mob-order {order: 1;}
  .Spiceway .section.contactus .form-btn-wrapper {margin-bottom: 0;}
}

/* @media (min-width: 992px) */
@media (min-width: 992px) {
   /* Header */
   /* ---------------------------------------------------------------------- */
   .Spiceway .navbar {padding: 30px 0;}
   .Spiceway .navbar-brand .brand-logo {height: 45px;}
   .Spiceway .navbar-light .navbar-nav .nav-link {padding: 8px 16px 8px 16px; font-size: 16px;}
}


/* colour theme settings */
 
/* Helper classes */
.mlr5 {margin-left: 5px; margin-right: 5px;}
.mt20 {margin-top: 20px;}
.mb0 {margin-bottom: 0;}
.pl0 {padding-left: 0 !important;}
.pdg-bot30 {padding-bottom: 30px;}
.min-w100 {min-width: 100px;}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

/* Google Font - Poppins */
/* Google Font - Roboto */

/* Body */
/* ---------------------------------------------------------------------- */
body {
  /* font-family: "Poppins","Helvetica Neue",Helvetica,Arial,sans-serif; */
  font-size: 16px;
  color: var(--on-surface-color);
  background-color: var(--surface-color);
}
/* Header */
/* ---------------------------------------------------------------------- */
.header-top {
  display: flex; 
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  background-color: var(--primary-color);
}
.login-right-wrapper {
  display: flex;
}
.header-top .contact-wrapper {
  display: flex;
  color: var(--white-color);
  font-size: 16px;
  font-weight: 500;
  margin-right: 8px;
}
a.tel-num {
  color: var(--white-color);
}
a.tel-num:hover {
  color: var(--white-color);
  text-decoration: none;
}
a.shopping-cart {
  font-size: 22px;
  height: 50px;
  color: var(--white-color);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .5rem;
}
a.shopping-cart:hover {
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
}
.shopping-cart .cart-count {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  font-weight: 700;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  line-height: 19px;
  text-align: center;
  color: var(--primary-color);
  background-color: var(--white-color);
  border: 2px solid;
  border-color: var(--primary-color);
}
a.login-register {
  color: var(--white-color);
  height: 50px;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: .5rem;
}
a.login-register:hover {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
  text-decoration: none;
} 
.login-register .user-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: var(--primary-color);
  background-color: var(--white-color);
  display: inline-block;
  line-height: 30px;
  text-align: center;
}
.login-register .user-name {display: none;}
.login-right-wrapper .drop-down-icon-hide::after{display: none;}
.login-right-wrapper .dropdown-menu.show {
	margin-top: 3px;
	border-radius: 0;
	padding: 5px 0;
	border: 0;
  font-size: 14px;
  box-shadow: var(--box-shadow-1);
}
.login-right-wrapper .dropdown-menu.show::after {
  border-bottom: 8px solid;
  border-color: var(--white-color);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  top:-7px;
  content: "";
  display: inline-block;
  right:8px;
  position: absolute;
  z-index: 1001; 
}
.loggedin-user {
  padding: 5px 10px;
  text-align: center;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
.hr-sep {
  display: flex;
  justify-content: center;
}
.hr-sep hr {
  border-color: var(--neutral-color-6);
  margin: 5px 0;
  width: 75%;
}
.dropdown-item {
  color: var(--on-surface-color);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--primary-color);
  text-decoration: none;
  background-color: var(--white-color);
}
.dropdown-item:focus, .dropdown-item:hover {
  color: var(--on-surface-color);
  text-decoration: none;
  background-color: var(--primary-color-opacity-35);
}
.user-actions i {margin-right: 10px;}
.view-cart-wrapper {
  width: 260px;
  padding: 10px;
}
.view-cart-wrapper .cart-item {
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid;
  border-color: var(--border-color-1);
}
.view-cart-wrapper .cart-item .cart-item-image {
  margin-right: 10px;
}
.view-cart-wrapper .cart-item .cart-item-image .cart-img-thumb {
  width: 50px;
  height: auto;
}
.view-cart-wrapper .cart-item .cart-item-text {
  width: 100%;
}
.view-cart-wrapper .cart-item .cart-item-text p {
  font-size: 14px;
  margin-bottom: 0;
  color: var(--primary-color);
}
.view-cart-wrapper .cart-item .cart-item-text .cart-item-qty {
  display: flex;
  justify-content: space-between;
}
.view-cart-wrapper .cart-sub-total {
  margin-bottom: 0;
  padding-top: 10px;
  font-weight: 500;
  display: flex;
  justify-content: end;
}
.view-cart-wrapper .cart-sub-total span {
  margin-right: 10px;
}
.view-cart-wrapper button.checkout-btn {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: var(--white-color);
  background-color: var(--primary-color);
  padding: 8px 16px;
  border: 1px solid;
  border-color: var(--primary-color);
  border-radius: 3px;
  width: auto;
  height: 30px;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.view-cart-wrapper button.checkout-btn:hover {
  background-color: var(--primary-color-opacity-85);
}
.view-cart-wrapper button.view-cart-btn {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: var(--primary-color);
  background-color: var(--white-color);
  border: 1px solid;
  border-color: var(--neutral-color-5);
  padding: 8px 16px;
  border-radius: 3px;
  width: auto;
  height: 30px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.view-cart-wrapper button.view-cart-btn:hover {
  color: var(--primary-color-opacity-85);
}
.search-wrapper {
  padding: 0 20px 10px 20px;
  display: flex;
  justify-content: center;
  background-color: var(--white-color);
}
.search-wrapper .form-wrapper {
  width: 100%;
}
.search-wrapper form input {
  border-color: var(--primary-color);
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
/* mobile navigation over */
.navbar-collapse {
	position: absolute;
  background-color: var(--white-color);
  width: 100%;
  top: 44px;
  left: 0;
  padding-left: 20px;
  z-index: 100;
}
.ico-chev::after {margin-left: 10px;}
.nav-menu {
  border-radius: 0;
  box-shadow: var(--box-shadow-1);
}
.navbar-nav.m-app-nav {margin-top: 20px;}
.navbar-brand .brand-logo {height: 30px;}
.navbar-toggler {
  border: 0;
  font-size: 24px;
}
.navbar-light .navbar-toggler i.fa-bars {
  color: var(--primary-color);
}
.navbar-light .navbar-toggler i.fa-times {
  color: var(--primary-color);
}
.navbar-brand {margin-right: 40px;}
.navbar-light .navbar-nav .nav-link {
  color: var(--on-surface-color);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 8px 16px 16px 0;
}
.navbar-light .navbar-nav .nav-link:hover {
  text-decoration: none; 
  color: var(--primary-color);
  opacity: 0.85;
}
.mob-ddl {margin-right: 20px;}
.navbar-light .navbar-nav .nav-link.active {color: var(--primary-color);}
.navbar-light .navbar-nav .nav-link:active, 
.navbar-light .navbar-nav .nav-link:focus {color: var(--primary-color);}
.opt-btns {
  display: flex;
  align-items: center;
}
/* Hero Banner */
/* ---------------------------------------------------------------------- */
.hero-banner  {
  background-image: linear-gradient(
  rgba(0,0,0,0.0), 
  rgba(0,0,0, 0.35)), 
  url(/static/media/hero-img.ac0ae0ed.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.hero-banner h1, h3 {
  color: var(--white-color);
  text-align: center;
}
.hero-banner h1 {
  font-weight: 700; 
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: 2.5rem;
  letter-spacing: 3px;
  word-spacing: 10px;
  text-shadow: -1px -1px 6px rgb(0 0 0 / 84%);
}
.hero-banner h3 {
  color: var(--white-color);
  text-align: center;
  font-weight: 600;
  text-transform: none;
}
.hero-text-wrapper {
  background-color: var(--hero-transparent-bg);
  padding: 2rem;
  margin: 2rem;
  border-radius: 8px;
}

/* Hero Other Pages */
/* ---------------------------------------------------------------------- */
.hero-banner-inner {
  background-image: linear-gradient(
  rgba(0,0,0,0.0), 
  rgba(0,0,0, 0.35)), 
  url(/static/media/inner-hero.b7bd6f88.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.hero-banner-inner h1 {
  color: var(--white-color);
  font-weight: 700; 
  text-transform: uppercase;
  font-size: 2.5rem;
  letter-spacing: 3px;
  word-spacing: 10px;
  text-shadow: -1px -1px 6px rgb(0 0 0 / 84%);
}

/* Site Breadcrumb */
/* ---------------------------------------------------------------------- */
.breadcrumb-container {
  padding: 20px;
}
.breadcrumb-in ul.site-breadcrumb {
	margin-bottom: 0;
	padding:0;
	list-style:none;
}
.breadcrumb-in ul.site-breadcrumb li {
	display: inline-block;
	padding-right: 20px;
	position: relative;
  font-size: 14px;
}
.breadcrumb-in ul.site-breadcrumb li a {
  color: var(--on-surface-color);
	font-size: 14px;
	text-decoration: none;
}
.breadcrumb-in ul.site-breadcrumb li a:hover {
  text-decoration: underline;
  text-decoration-thickness: 1px;
   text-underline-offset: 3px;
}
.breadcrumb-in ul.site-breadcrumb .current {
  color: var(--primary-color);
}
.breadcrumb-in ul.site-breadcrumb li::after {
  content: '>';
  font-size: 14px;
  position: absolute;
  right: 6px;
  top: 1px;
}
.breadcrumb-in ul.site-breadcrumb li:last-child::after {content: '';}

/* Product Categories */
/* ---------------------------------------------------------------------- */ 
.section {
  padding-top: 30px;
  background-color: var(--white-color);
}
.section.products {
  padding: 30px 0;
  background-color: var(--primary-color);
  background-image: linear-gradient( 
    rgba(0,0,0,0.0), 
    rgba(0,0,0, 0.35)), 
    url(/static/media/prd_bg.3dbd4053.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
h2 {
  text-align: center;
  word-spacing: 3px;
  margin-bottom: 30px;
} 
.qty-wrapper {background-color: var(--neutral-color-6); height: 30px;}
.prd-item img {width: 100%;}
.prd-item {
  margin-bottom: 30px;
  text-align: center;
}
.prd-item .prd-item-link {
  background-color: var(--white-color);
  text-decoration: none;
  display: inline-block;
  width: 100%;
}
.prd-item .prd-item-link img {margin-bottom: 4px;}
.prd-count-inner {
  display: flex;
  justify-content: space-between;
}
.prd-count-inner.sing-btn {justify-content: end;}
.prd-count-inner button {
  font-size: 12px;
  padding: 5px 10px;
  background-color: var(--neutral-color-6);
  border: 0;
  width: 40px;
  height: 30px;
}
.icon-sub, .icon-add {
  font-size: 12px;
  padding: 5px 10px;
  background-color: var(--neutral-color-6);
  border: 0;
  width: 40px;
  height: 30px;
}
.qty-no input {
  width: 50px;
  height: 30px;
  border: 1px solid;
  border-color: var(--neutral-color-5);
  background-color: var(--white-color);
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}

/* remove scroll in number input */
.qty-no input[type=number]::-webkit-inner-spin-button, 
.qty-no input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.prd-count-inner button.add-btn {
  font-size: 14px;
  font-weight: 500;
  color: var(--white-color);
  background-color: var(--primary-color);
  padding: 5px 8px;
  border: 0;
  width: auto;
  height: 30px;
}
.prd-count-inner button.add-btn:hover {
  background-color: var(--primary-color-opacity-85);
}
.section.products .prd-item .prd-item-link:hover {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}
a.prd-item-link:hover {
  text-decoration: none;
}
.prd-item .prd-item-text h5 {
  font-size: 16px;
  color: var(--on-surface-color);
  margin-bottom: 0;
  padding: 8px 0;
  text-transform: uppercase;
}
.bdr-bot {
  border-bottom: 1px solid;
  border-color: var(--neutral-color-6);
}
.header-wrapper {
  display: flex;
  flex-direction: column;
}
.section.products .header-wrapper h2 {color: var(--white-color);}
/* Product Carousel Styles */
.section .owl-theme .owl-dots .owl-dot.active span, .section .owl-theme .owl-dots .owl-dot:hover span {
  background: var(--primary-color);
}
.owl-theme .owl-nav [class*='owl-']:hover {
  background-color: var(--primary-color) !important;
}
.owl-dots {margin-top: 10px;}
.owl-nav i {font-size: 14px;}


.section.products button {
  color: var(--white-color);
  background-color: var(--primary-color);
  border-radius: 0;
  font-weight: 500;
  margin-top: 20px;
}
.section.products button:hover {
  background-color: var(--primary-color-opacity-85);
}
.section.products button span i {
  margin-left: 10px;
  font-size: 14px;
}
/* Product Range */
/* ---------------------------------------------------------------------- */ 
.section.productRange {
  background-color: var(--white-color);
}
.section.productlisting {
  background-color: var(--white-color);
}
.item-count {
  background-color: var(--neutral-color-6);
  padding: 15px;
  margin-bottom: 20px;
}
.item-count h6 {margin-bottom: 0;}

.section.productlisting {
  background-color: var(--white-color);
  padding-top: 10px;
}
.items-list a {
  padding: 8px 12px;
  color: var(--on-surface-color);
}
.items-list a:hover {
  background-color: var(--primary-color-opacity-35);
}
.items-list a.active {
  background-color: var(--primary-color);
  border: 0;
}
.mbl-prd-cat {
  margin-bottom: 20px;
}
.mbl-prd-cat .btn-cat {
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
}
.mbl-prd-cat .dropdown-menu button {
  background-color: var(--white-color);
}
.mh-at {min-height: auto !important; text-align: center !important;}

.load-more {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 20px 0;
}

.load-more a.load-more-link {
  color: var(--primary-color);
  margin-bottom: 0;
  padding: 10px 20px;
  width: 200px;
}.load-more a.load-more-link:hover {
  text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
}
/* Product Details */
/* ---------------------------------------------------------------------- */ 
.pro-img-wrapper {
  display: flex;
  flex-direction: column;
}
.pro-img-wrapper .prd-img-large img {
  border: 1px solid;
  border-color: var(--neutral-color-5);
  margin-bottom: 10px;
}
.pro-img-wrapper .prd-img-thumb {display: flex;}
.pro-img-wrapper .prd-img-thumb img {
  max-width: 100px;
  height: auto;
  margin-right: 10px;
  border: 1px solid;
  border-color: var(--neutral-color-5);
  opacity: .75;
}
.pro-img-wrapper .prd-img-thumb img.img-active {
  border-color: var(--primary-color);
  opacity: 1;
}
.prd-details-text {margin-top: 20px;}
.prd-details-text .prd-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}
.prd-details-text .prd-more-details p {margin-bottom: 10px;}
.prd-details-text .prd-price {
  font-size: 16px;
  color: var(--primary-color);
  font-weight: 600;
  margin-bottom: 20px;
}
.prd-count-inner.p-det {justify-content: flex-start;}
.prd-count-inner .select-weight {width:60%; margin-right: 20px;}
.select-qty {display: inline-block;}
.select-qty p {margin-bottom: 8px;}
.select-qty .qty-wrapper {height: 38px;}
.select-qty .qty-wrapper .a-fix {
    height: 38px;
    line-height: 38px;
}
.ft-bld {font-weight: 500;}
.qty-select-details p {font-weight: 500;}
.prd-buttons {margin-top: 30px;}
.prd-buttons .prd-add {
  border: 2px solid;
  border-color: var(--primary-color);
  color: var(--primary-color);
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  padding: 10px 0;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}
.prd-buttons .prd-add:hover {text-decoration: none;}
.prd-buttons .prd-checkout {
  border: 2px solid;
  border-color: var(--primary-color);
  background: var(--primary-color);
  color: var(--white-color);
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  padding: 10px 0;
  text-align: center;
  width: 100%;
}
.prd-buttons .prd-checkout:hover {
  text-decoration: none;
  background: var(--primary-color-opacity-85);
}
.comm-details {margin-top: 50px; margin-bottom: 20px;}

/* Accordion */
.accordion .card {
	border-radius: 0;
	border-top:0;
  background-color: var(--neutral-color-6);
	margin-bottom: 3px;
}
.accordion .card-header {
  background-color: var(--neutral-color-5);
    cursor: pointer;
    padding: 0;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
	border-radius: 0;
}
.accordion .card-header button {
	margin: 0;
	padding: 10px 15px 10px;
	font-size: 14px;
  font-weight: 600;
  color: var(--neutral-color-3);
	display: block;
}
.accordion .card-header button:focus {outline: 0; box-shadow: none; text-decoration: none;}
.accordion .card-header .btn-link:hover  {text-decoration: none;}
.accordion .card-header button:after {
	font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: '\f068';
    position: absolute;
    right: 10px;
    top: 10px;
	font-size: 12px;
}
.accordion .card-header button.collapsed:after {
	content: '\f067';
}
/* Product Checkout */
/* ---------------------------------------------------------------------- */ 
.sp-cart .search-wrapper {display: none;}
.container.checkout {padding-top: 60px; padding-bottom: 60px;}
.container.checkout .header-wrapper h2 {margin-bottom: 60px;}
.checkout .table-responsive {margin-bottom: 30px;}
.checkout .table {font-size: 14px;}
.checkout .table tr th {font-weight: 600;}
.checkout .prd-item-wrap {
  display: flex;
  width: 360px;
}
.checkout .img-small {
  width: 100px;
  height: auto;
  margin-right: 10px;
}
.checkout .prd-item-name {
  font-weight: 400;
}
.checkout .select-qty {min-width: 150px;}
.checkout .qty-wrapper {display: inline-block;}


.cart-details {display: flex; margin-bottom: 30px;}
.cart-details .align-option {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cart-details .cart-sub-total .st-item {
	min-width: 165px;
    padding: 10px;
    border: 1px solid #eee;
}
.mr10 {margin-right: 10px;}
.mr20 {margin-right: 20px;}

.cart-details .st-item {font-weight: 500;}
.cart-details .total-to-pay .st-item {font-size: 16px; font-weight: 700;}

.cart-details .cart-button-wrapper {
	margin-top: 30px;
	display:flex;
	flex-direction: column;
}
.cart-details .cart-button-wrapper a:first-child {margin-bottom: 15px;}

.cart-item-count {margin-bottom: 10px;}
.cart-badge {
  width: 30px;
    height: 30px;
    border-radius: 15px;
    line-height: 24px;
    background-color: var(--primary-color);
    color: var(--white-color);
}
.cart-buttons {
  display: flex;
  flex-direction: column;
  align-items: self-end;
  margin-top: 30px;
}
.cart-buttons a {
  text-align: center;
  padding: 10px 0;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  width: 100%;
}
.cart-buttons a.crt-btn-checkout {
  border: 2px solid;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  color: var(--white-color);
  margin-top: 15px;
}
.cart-buttons a.crt-btn-cont {
  border: 2px solid;
  border-color: var(--primary-color);
  background-color: var(--white-color);
  color: var(--primary-color);
}
.cart-buttons a.crt-btn-checkout:hover {
  background-color: var(--primary-color-opacity-85);

}

/* Best Sellers */
/* ---------------------------------------------------------------------- */ 
.prd-item {
  background-color: var(--white-color);
  border: 1px solid rgba(242, 235, 235, 0.45);
  box-shadow: var(--box-shadow-1);
  padding: 12px;   
}
.prd-item:hover {
  border: 1px solid;
  border-color: var(--primary-color);
}
.prd-item img {
  height: 230px;
}
.prd-item .prd-item-text {
  text-align: left;
  min-height: 90px;
}
.prd-item .prd-item-text h5 {
  font-weight: 400;
  text-transform: none;
}
.prd-item .prd-item-text h6 {
  font-size: 16px; 
  color: var(--primary-color);
  font-weight: 600;
  margin-bottom: 8px;
}
/* Testimonial */
/* ---------------------------------------------------------------------- */ 
.section.testimonials {
  padding: 30px 0;
  background-color: var(--primary-color);
}
.section.testimonials .header-wrapper h2 {color: var(--white-color);}
.testimonial-wrapper {
  background-color: var(--white-color);
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 20px;
  border: 1px solid rgba(242, 235, 235, 0.45);
  box-shadow: var(--box-shadow-1);
}
.testimonial-header {display: flex;}
.avt-img {margin-right: 10px;}
.avt-img-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.avt-img-name h6 {
  margin-bottom: 3px;
}
.ratings {
  font-size: 11px;
  color: var(--bright-color);
}
.ratings i {margin: 0 1px;}
.testimonial-body p {
  margin-bottom: 0;
  padding-top: 20px;
  text-align: left;
}
/* Promotions */
/* ---------------------------------------------------------------------- */ 
.section.promotions {
  padding: 0;
  margin-top: 30px;
}
.promo-wrapper {
  background-color: var(--neutral-color-6);
  height: 220px;
}
.promo-text-wrapper {
  display: flex;
  align-items: center;
  height: 220px;
}
.promo-text {
  padding: 20px;
  text-align: left;
}
.promo-text h5 {margin-bottom: 16px;}
.form-wrapper button {
  color: var(--white-color);
  background-color: var(--primary-color);
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; 
  font-weight: 500;
}
.form-wrapper button:hover {
  color: var(--white-color);
  background-color: var(--primary-color-opacity-85);
}
.form-control:focus {
  background-color: var(--white-color);
  border-color: var(--primary-color);
  outline: 0;
  box-shadow: var(--primary-box-shadow);
}
.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: var(--primary-box-shadow);
}
.email-small-text {
  margin-bottom: 0;
  margin-top: 16px;
  font-size: 12px;
}
.promo-bg-img {
    background-image: url(/static/media/promo-image.964f83cb.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 220px;
    display: none;
}
/* Our Blog */
/* ---------------------------------------------------------------------- */ 
.section.blog img {width: 100%;}
.section.blog .prd-item-text {
  text-align: left;
  padding: 10px 0;
}
.section.blog h6 {
  color: var(--neutral-color-4);
  font-size: 14px;
}
.section.blog button {
  color: var(--white-color);
  background-color: var(--primary-color);
  border-radius: 0;
  font-weight: 500;
}
.section.blog button:hover {
  background-color: var(--primary-color-opacity-85);
}
.section.blog button span i {
  margin-left: 10px;
  font-size: 14px;
}
.section.blog h4 {font-size: 20px;}
/* Footer */
/* ---------------------------------------------------------------------- */ 
.pg-footer {
  margin-top: 30px;
  padding: 30px 0 70px 0;
  background-color: var(--primary-color);
  position: relative;
}
.pg-footer .brand-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.pg-footer .brand-wrapper .logo-wrap {margin-bottom: 10px;}
.pg-footer .brand-wrapper .addr-wrap h6 {
  font-size: 13px;
  color: var(--white-color);
  text-transform: uppercase;
}
.pg-footer .brand-wrapper .addr-wrap .addr-lines span {
  color: var(--white-color);
  font-size: 12px;
  display: block;
  letter-spacing: 1px;
}
.pg-footer .social-icons a {
  font-size: 20px;
  display: inline-block;
  color: var(--white-color);
  padding: 5px 0;
  margin-right: 30px;
}
.pg-footer .social-icons a:hover {-webkit-transform: translateY(1px);transform: translateY(1px);}
.pg-footer .social-icons a i {font-size: 24px;}
.pg-footer .pay_cards {display: flex; margin-top: 10px;}
.pg-footer .payment-icons span {
  padding: 8px;
  display: inline-block;
}
.pg-footer .payment-icons span img {
   height: 30px;
}
.pg-footer .copy {
  font-size: 10px;
  color: var(--white-color);
  text-align: left;
  letter-spacing: .5px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}
.pg-footer .copy .copy-text {padding-right: 10px; margin-bottom: 5px;}
.pg-footer .copy a {
  color: var(--white-color);
}
.pg-footer .copy .copy-sep {margin: 0 10px;}
.pg-footer .copy a:hover {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
}

.pg-footer .help {
  position: absolute;
  bottom: 15px;
  left: 15px;
}
.pg-footer .help .help-cover {
  display: inline-block;
  color: var(--white-color);
  background-color: var(--black-color);
  padding: 8px 16px;
  height: 40px;
  border-radius: 20px;
}
.pg-footer .help .help-cover > * {display: inline-block;}
.pg-footer .help .help-cover P {
  margin-bottom: 0;
  margin-left: 5px;
  font-weight: 500;
}
.pg-footer .top-up  {
  position: absolute;
  top: -20px;
  bottom: 0;
  right: 15px;
}
.pg-footer .top-up .icon-up {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: var(--black-color);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--box-shadow-1);
}
.pg-footer .top-up .icon-up i { 
  font-size: 20px;
  color: var(--white-color);
}

/* ====================================================================== */
/* Responsive Media Query */
/* ====================================================================== */
@media (min-width: 576px) {
  .search-wrapper .form-wrapper {
    width: 80%;
  }
  .pg-footer .copy {flex-direction: row;}
}
/* ----------------------------------------------------------------------- */
@media (min-width: 768px) {
  .header-top {justify-content: flex-end;}
  .mob-ddl {margin-right: 0;}
  .login-register .user-icon {margin-right: 5px;}

  .navbar-collapse {
    position: relative;
    width: auto;
    top: unset;
    left: unset;
    padding-left: 0;
    z-index: 1;
  }

  .search-wrapper {padding-bottom: 30px;}
  .search-wrapper .form-wrapper {width: 70%;}
  .login-register .user-name {display: block;}
  .section {padding-top: 60px;}
  .owl-nav {margin-top: 0 !important;}

  .pg-footer .help {bottom: 0;top: -20px;}
  .pg-footer .top-up  {top: -70px;}
  .pg-footer .top-up .icon-up {box-shadow: var(--box-shadow-2); }
  .prd-details-text {margin-top: 0;}
  .comm-details {margin-top: 50px; margin-bottom: 0;}



  .cart-buttons {flex-direction: row;}
  .cart-buttons a.crt-btn-cont {margin-right: 15px;}
  .cart-buttons a.crt-btn-checkout {margin-left: 15px;}
  
}
/* ----------------------------------------------------------------------- */
@media (min-width: 992px) {
  .navbar {padding: 30px 0;}
  .navbar-light .navbar-nav .nav-link {padding: 8px 16px; font-size: 16px;}
  .navbar-nav.m-app-nav {margin-top: 0;}
  .section.products {padding: 100px 0;}
  .section.testimonials {padding: 100px 0;}
  .section.promotions {padding: 0; margin-top: 60px;}
  .promo-bg-img {display: block;}
  .pg-footer {margin-top: 60px; padding: 60px 0 60px 0;}
  .pg-footer .brand-wrapper {flex-direction: row;}
  .pg-footer .brand-wrapper .logo-wrap {margin-bottom: 0;margin-right: 10px;}
  .section.blog .in-text-wrapper {min-height: 215px;}
  .pg-footer .pay_cards {margin-top: 0;}
  .search-wrapper .form-wrapper {width: 60%;}


  .prd-buttons .prd-add, .prd-buttons .prd-checkout {width: 47%;}
  .prd-buttons .prd-add {margin-right: 20px;}
  .checkout .table {font-size: 16px;}


  .cart-buttons a {width: 260px;}
}
/* ----------------------------------------------------------------------- */
@media (min-width: 1200px) {
  .search-wrapper .form-wrapper {
    width: 50%;
  }
  .mob-order {order: 2;}
}
/* ----------------------------------------------------------------------- */

.links{
	background-color: transparent;
    color: #1890ff !important;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    transition: color .3s;
}

 
.login-page {
	min-height: 100vh;
}

.login-page-container {
  height: 100vh;
  min-height: 400px;
  width: 60%;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}

.login-page-image-container {
  height: 100%;
  width: 50%;

  background: rgba(94,129,244,5%);
  background-image: url(/static/media/Group.82404824.jpg);
  -ms-background-size: cover;
  background-size: cover;
}

.login-page-image-container-content img {
  width: 742px;
    float: left;
    height: 653px;
  float: left;
}

.login-page-image-container-content {
  display: flex !important;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  flex-direction: column !important;
  align-items: flex-start !important;
}

.login-page-form-container {
  height: inherit;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.login-page-form {
  width: 70%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-items: flex-start;
}

.login-page-form > * {
  width: 100%;
  text-align: left;
}

/* .login-page-form img {
  width: 160px;
} */

.login-form-elements-mt {
  margin-top: 16px;
}
.eco-app-content{
  /* background-color: green; */
  overflow: auto!important;
  max-width: calc(100vw - 64px);
  max-height: calc(100vh - 60px);
}

.signIn__btn {
  background-color: #5E81F4 !important;
  border: none !important;
  color: white !important;
  width: 178px;
  height: 46px !important;
  border-radius: 8px !important;
  margin-top: 30px;
}

.signUp__btn {
  background-color: #5E81F4 !important;
  border: none !important;
  color: white !important;
  width: 178px;
  height: 46px !important;
  border-radius: 8px !important;
  font-size: 17px;
}


 .login-spinner {
  display: flex !important;
    justify-content: center !important;
 }






























































































/* MEDIA QUERIES */
@media (max-width: 768px) {
  .login-page-image-container {
    display: none;
  }
  
  .login-page-form-container {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .login-page-container {
    height: 100vh;
    width: 100%;
  }

  .login-page-form {
    width: 75%;
  }
}

@media (max-height: 380px) and (orientation:landscape) {
  .login-form-elements-mt {
    margin-top: 4px
  }
}

/* @media(min-width:600px){
  .eco-app-content{
    overflow: scroll!important;
    max-width: 100vw;
  }
} */


/* VALIDATION MESSAGE */
.help-text-with-errors{
  color: red;
   
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield !important; /* Firefox */
}

.image_validation{
    color: red;
}

.eco-select .eco-select__control{
    background: #f2f2f2;
    border: 1px solid #f2f2f2;
}
.thumbnail-image {
    width: 100px;
    height: 100px;
    padding: 8px;
    overflow: hidden;
}

.thumbnail-image>img {
    object-fit: contain !important;

}

.eco-app-content {
    max-width: calc(100vw);
}

.anticon {
    display: inline-block !important;
    vertical-align: 0 !important;
}

/* DASHBOARD ICON   */
.plus_icon_dash {
    height: 17px !important;
    width: 17px !important;
    margin: 5px;
}

.eco-typography {
    /* margin-top: 6px !important; */
}

/* .ant-table-cell {
    color : #8181A5 !important
} */

.add__btn {
    background-color: rgba(94, 129, 244, 10%) !important;
    border: none !important;
    color: #5E81F4 !important;
    border-radius: 10px !important;
}

.add__btn:hover {
    color: #fff !important;
    background-color: #5E81F4 !important;
}

.btn__submit {
    color: #5E81F4 !important;
    background-color: rgba(94, 129, 244, 10%) !important;

    border: none !important;
    /* color: #fff !important; */
}

.btn__submit:hover {
    color: #fff !important;
    background-color: #5E81F4 !important;

}

.image-upload-section {
    border: 2px dashed #cecece;
    border-radius: 10px;
    min-height: 150px;
    padding: 10px;
}

/* Publish Unpublish Tag */
.status_btn {
    width: 106px !important;
    height: 38px !important;
    border-radius: 8px !important;
    justify-content: center !important;
}
.eco-main-side-panel__logo-wrapper a img{
    height: auto!important;
}
.product-page-content{
    padding: 0!important;
}
.product-page-header{
    padding: 20px;
}
.site-listing{
    padding: 20px;
    background-color: #F0F1F4;
}
.eco-header__location-switcher .eco-stack__item{
    max-width: 0 10px !important;
}
.add-new-site-btn .eco-icon{
    fill: #fff!important;
}
.eco-tag{
    /* display: flex; */
    justify-content: center;
    width: 100px;
}

.settings__overlay{
    width: 352px;
    height: 100%;  
    position: fixed;
    z-index: 1;
    top: 162px;
    left: 65px;
    overflow-x: hidden;
    transition: all .6s ease-in;
    background-color: #ffffff;
    box-shadow: 0px 0px 0px #5a5a5a;
    border-top: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    z-index: 99991;
    overflow: scroll;
}
.settings-box{
    width: 300px;
    height: 100px;
    border: 1px solid #ECECF2;
    padding: 10px 30px;
    border-radius: 10px;
    margin:0!important;
}

.settings-section{
    padding: 20px;
}

.settings-div{
    border: 2px solid #f0f0f0;
    border-radius: 6px;
}

.settings-card{
    padding: 10px;
    padding-bottom: 20px;
}

.main-title{
    border-left: 5px solid #EEEEEE;
    padding-left: 8px;
}

.h-line{
    background: #f0f0f0;
    margin:0 auto;
    padding: 0;
}
.settings-card{
    border: 1px solid #C6C7D2;
    padding: 10px;
}
.save-btn, .cancel-btn{
    width: 80px;
}
.unpublish-btn, .unpublish-cancel-btn{
    width: 150px;
}
.theme-selection{
    padding: 10px;
}
.theme-selection:hover{
    background-color: #E6F6FF;
    color: #039BE5!important;
    cursor: pointer;
}
.ant-tabs-tab-btn{
    width: 100%;
}
.theme-image{
    width:250px;
    height: 150px;
    border-radius: 8px;
}
.theme-image img{
    width:100%;
    height:100%;
    object-fit:cover;
    border-radius: 8px;
}
.themes-card {
    border: 1px solid #ECECF2;
    padding: 20px;
    border-radius: 12px;
}
.theme-tabs .ant-tabs-nav-list .ant-tabs-tab-active {
    background-color: #5E81F4!important;
}
.theme-tabs .ant-tabs-nav-list .ant-tabs-tab-active .ant-tabs-tab-btn{
    color:#fff!important;
}
.theme-tabs .ant-tabs-tab{
    border-radius: 8px;
}
.theme-tabs .ant-tabs-nav-list .ant-tabs-tab {
    background-color: #EFF2FE;
}
.theme-tabs .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn{
    color:#5E81F4;
}
.theme-image-card {
    cursor: pointer;
}
.active-theme-image-card {
    border: 2px solid greenyellow;
    border-radius: 12px;
}
.element-input{
    border: transparent!important;
    background-color: transparent!important;
}
.element-input:focus{
    border: 1px solid #cecece!important;
    background-color: #fff!important;
    box-shadow: none!important;
}
.text-direction-btn{
    width: 30px!important;
}
.text-direction-btn .eco-button__content-wrapper{
    padding: 0!important;
}
.widget__overlay {
    width: 352px;
    height: 100%;  
    position: fixed;
    z-index: 1;
    top: 162px;
    left: 65px;
    overflow-x: hidden;
    transition: all .6s ease-in;
    background-color: #ffffff;
    box-shadow: 0px 0px 0px #5a5a5a;
    border-top: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    z-index: 99991;
    overflow: scroll;
}

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
.widget-div {
    padding-right: 10px;
}
.widget-div::-webkit-scrollbar {
    width: 5px;
  }
  
.widget-div::-webkit-scrollbar-track {
background: #F2F2F2;
}

.widget-div::-webkit-scrollbar-thumb {
background: #BDBDBD;
}

.widget-div::-webkit-scrollbar-thumb:hover {
background: #6E6E6E;
}


.widget__overlay__hide {
    width: 352px !important;
    height: 100% !important;
    position: fixed !important;
    z-index: 1 !important;
    top: 56px !important;
    left: 65px !important;
    overflow-x: hidden !important;
    transition: all .6s ease-in !important;
    
    box-shadow: 0px 0px 0px #5a5a5a !important;
    border-top: 1px solid !important;
    border-right: 1px solid !important;
    border-bottom: 1px solid !important;
    background-color: #000 !important;
    opacity: .5 !important;
    transition: .4s !important;
    width: 100% !important;
    z-index: 99990 !important;
}
.close-name {
    font-size: 20px;
    color: black;
}
.hideWidget__overlay {
    width: 0;
}
.widget-tabs .ant-tabs-nav-list{
    width:100%;
}
.widget-tabs .ant-tabs-content-holder{
    border: none!important;
}
.card__overlay {
    width: 100%;
    height: 150px;
}
.card__overlay:active{
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAfCAYAAAAfrhY5AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGKSURBVHgB7Ze/TsJAHMe/15bEsXEi8UhwpCx1on0QMbL5BryAEeODuEHEhUdwK0w61bESalh5AMl513haFrg7obfwWS5tLr/v9/en7ZWAU683m56HRxAS8ksfh4SxyXpN+stlNieFcI28Hlx0k9X6C6Hr+6c8Y4SolhOHsJDQxjmDHVYO7OHbFMdRXBtKzzB+GhZrpeJC8Hk8QhxH3MDI2IC2uBSmlBbXjQY1NmD8nEdRxE0M0b26RpLMYIJy5qqZ6VRASVyWWgWdFni7NpR7HEWd3/vtdqtYgyDgH6q//XIGRDvy/HNr7J09F4HiuANdkmTKDfTwL3GRsRgssZaDBUELg7tbDO4fkKbvJbNDLBZ5sTfP822hd5ddBLjs9goDIhuJLHWaphvTriosUBo4aUAFVWFlcWlgn/sE2i+Z8gxIdEpdRvv1KlsghUyFjcTLBpLpzFhYYPMMdzzJWBIn/PwMSzgM7A1WYBPHc3FTdfZCr+aRvvPBcV12QUAmODA/Sb7wP+Iwy7L5NzMTqx58eh2YAAAAAElFTkSuQmCC),default;
    border: 2px dashed #039BE5;
}
.card__overlay:active h5{
    color: #039BE5;
}
.card__overlay:active .eco-icon{
    fill: #039BE5;
}

/* .widget-tab{
    background: red;
} */
 
.ant-tabs-nav-list{
    border-radius: 0;
    border: 1px solid #F0F0F0;
}


.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab{
    border: none;
}

.ant-tabs-nav::before{
    border-bottom: none;
}
.search-input input{
    font-style: italic;
}

/* 

::-webkit-input-placeholder {
    font-style: italic;
 }
 :-moz-placeholder {
    font-style: italic;  
 }
 ::-moz-placeholder {
    font-style: italic;  
 }
 :-ms-input-placeholder {  
    font-style: italic; 
 } */
.eco-layout .eco-layout__content {

  overflow: auto !important;
}

.react-grid-layout {
  margin: 10px;
  min-height: 100vh !important;
}

.home__right__panel {
  width: 30% !important;
  border: 1px solid black;
}


.PreviewLayout .ant-row:hover {
  cursor: move;

}

.widget__class {
  width: 86px !important;
  height: 85px !important;
  margin-bottom: 8px;
  -webkit-margin-end: 15px;
          margin-inline-end: 15px;
}

.widget__class_div {
  margin-bottom: 10px !important;
  display: flex;
  padding: 0 20px;
  -webkit-margin-before: 10px;
          margin-block-start: 10px;
  -webkit-transform: translate(2px, 0);
          transform: translate(2px, 0);
}

.widget__inside {
  display: flex;
  flex-direction: column;

}

.icon__module__main {
  flex: 0 0 auto;
  height: 45px;
  margin-top: 4px;
  fill: #617379;
}

.icon__module {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  max-width: 100%;
}



/* .widget__name {
    text-align: center;
    color: inherit !important;
    font-size: 13px;
    line-height: 1.1;
    padding-inline-end: 5px;
    padding-inline-start: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 5px;
     
  } */
/* .eco-right-panel__overlay {
    background-color: #fff !important;
    opacity: 0 !important;
    transition: .4s !important;
    width: 100% !important;
    z-index: 0 !important;
} */


/*  */
.reportWidgets {
  width: 100px;
  height: 100px;
}

/* DELETE LATER */

.uploader {
  border: 2px dashed #cecece;

  border-radius: 10px;
  min-height: 150px;
  padding: 10px;
}

.file-text {
  margin: 31px;
  padding: 6px 126px;

  text-align: center;

}

/* .uploader > input {
  display: none;
} */
/* DELETE LATER */
/* 
.border-background {
  width: auto !important;
  height: auto !important;
} */
/* .PreviewLayout .layout{
  height: 100% !important;
}
.PreviewLayout .layout .react-grid-item > div{
  width: 97% !important;
} */


/* CLOSE ICON */
.hide-button {
  position: absolute;
  right: 2px;
  top: -9px;
  cursor: pointer;
}

.eco-button.eco-button--default:focus { 
  box-shadow: none !important;
}

/* REACT GRID ITEM */
.react-grid-item {
  box-sizing: border-box;
  overflow: hidden!important;
}

.react-grid-item:not(.react-grid-placeholder) {
  border-radius: 5px;
  /* padding: 30px; */
  /* width: 200px; */
  /* height: 150px; */
  /* border: 1px solid rgb(206, 206, 206); */
}

.react-grid-item.resizing {
  opacity: 0.9;
}

.react-grid-item.static {
  background: #cce;
}

.react-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
}

.react-grid-item .minMax {
  font-size: 12px;
}

.react-grid-item .add {
  cursor: pointer;
}

.global-header {
  width: 100%;
}

/* .preview__btn__header:hover {
  background-color:  rgba(94,129,244,10%) !important;  
  color: #5E81F4 !important; 
} */


.preview__btn__header {
  color: #fff !important;
  background-color: #5E81F4 !important;
  border: #5E81F4 !important;
  width: 112.77px !important;
  height: 36px !important;
  border-radius: 8px !important;
}

.size__dropdown {
  background: #EAEAF1 !important;
  border: #EAEAF1 !important;
}

.size__dropdown .eco-typography {
  font-weight: 500 !important;
}

.react-grid-layout {
  background-color: #fff;
}

/* HEADER PANEL */
.tablet-rotate {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.colored-view {
  fill: #039BE5 !important;
}

.colored-view-tab {
  fill: #039BE5 !important;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);

}
.home-page-content{
  background-color: #e5e5e7;
  padding: 20px;
  max-height: calc(100vh - 132px);
  overflow-y: scroll;
}
.layout-mobile{
  padding: 50px;
}
.publish-btn{
  width: 150px;
}

body > iframe {
  pointer-events: none;
}
.layout-mobile .react-grid-layout{
    margin: 10px;
    min-height: 640px !important; 
    overflow-y: auto;
    max-height: 640px !important;
    max-width: 360px;
    margin: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; 
    outline: 20px solid #000;
    border-radius: 20px;
    overflow-x: hidden;

}
.contact__us__container {
    padding: 12px 24px 24px 24px;
    margin: 48px 220px;

}

.submit__btn {
    float: right;
    background-color: #5E81F4 !important;
    color: aliceblue !important;
    border: #5E81F4 !important;
}

.carousel-img {
    width: 100% !important;
    height: 400px;  
}



.react-video-player {
    width: 100% !important;
    height: 100% !important;
    /* padding: 20px !important; */
}

.react-video-player > video{
    object-fit: fill;
}

/* PRICING PLAN CSS  */
.eco-header {
    height: 66px !important;
}

.add-company-btn .eco-icon {
    fill: #fff !important;
}

.green-icon {
    fill: #2BD67B !important;
}

.change-btn {
    background-color: #81838C !important;
    color: #fff !important;
}

.app-card-content {
    background-color: #fff;
    border-radius: 4px;
    height: 90% !important;
    padding: 10px;
}

.buy-more-btn {
    color: #039BE5 !important;
    border-color: #039BE5 !important;
}

.filter-section {
    background-color: #fff;
    width: 50%;
    height: 70px;
    border-radius: 3px;
    margin: -32px auto auto;
    z-index: 1;
}

.ant-collapse-header-text,
.ant-collapse-content {
    text-align: start;
    font-weight: 600;
}

.eco-app-content {
    max-height: calc(100vh - 100px);
}

.no-border-card {
    border: none !important;
    height: 90%;
    padding: 10px;
}

.org-overview-content {
    min-height: calc(100vh - 100px);
    background-color: #F5F5F5;
    padding: 20px;
    height: auto;
    width: 100%;
}

.category-content {
    min-height: calc(100vh - 100px);
    padding: 20px;
    height: auto;
    width: 40%;
}

.prev-btn {
    color: #039BE5 !important;
}

.prev-btn .eco-icon {
    fill: #039BE5 !important;
}

.next-btn .eco-icon {
    fill: #fff !important;
}

.disabled-label {
    color: #d6d6d6 !important;
}

.squared-pill {
    border-radius: 3px !important;
    color: #039BE5 !important;
}

.detail-page {
    margin: 20px;
}

.add-btn {
    background-color: #EEC446 !important;
    border-color: #EEC446 !important;
    color: #fff !important;
    width: 200px;
}

.image-card {
    width: 75px;
    height: 70px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.app-card-container {
    width: 70%;
    max-height: 50vh;
    overflow-y: auto;
    padding: 10px;
}

.app-card {
    width: 100%;
    margin: 10px 5px;
}

.app-card-container::-webkit-scrollbar,.accordian-section::-webkit-scrollbar, .pages-scroll::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #808080;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #808080;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #808080;
}

.plan-card {
    background-color: #FAFAFA;
}

.plan-card-selected {
    background-color: #101C3D;
    color: #fff !important;
}

.green-tag {
    background-color: #2BD67B !important;
    color: #fff !important;
}

.control-arrow {
    /* background-color: #81838C !important;
     */
     opacity: 1!important;
}

.org-tag {
    background-color: #101C3D !important;
    color: #fff !important;
    width: 120px !important;
    height: 30px !important;

    display: flex;
    justify-content: center;
}

.org-tag-update {
    background-color: transparent !important;

    color: rgb(0, 0, 0) !important;
    width: 120px !important;
    height: 30px !important;

    display: flex;
    justify-content: center;
}

.tier-tag {
    background-color: #FFA800 !important;
    color: #fff !important;

    width: 83px !important;
    height: 30px !important;

    display: flex;
    justify-content: center;
}

.tier-tag-card {
    background-color: #2BD67B !important;
    color: #fff !important;

    width: 97px !important;
    height: 27px !important;

    /* display: flex; */
    justify-content: center;
}

.gray-tag {
    background-color: #E9EEF3 !important;
    color: #000 !important;
    font-weight: 600 !important;
}

.pricing-plan-page {
    padding: 40px 10px;
}

.pricing-plan-table td {
    border-right: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
}

.pricing-plan-table th {
    padding: 15px 10px;
}

.pricing-plan-table th {
    padding: 15px 10px;
    border-right: 1px solid #cecece;
    text-align: center;
}

.select-plan-btn {
    color: #039BE5 !important;
    border-color: #039BE5 !important;
    border-radius: 20px !important;
}

.current-plan-btn {
    color: #fff !important;
    border-color: #039BE5 !important;
    background-color: #039BE5 !important;
    border-radius: 20px !important;
}

.select-plan-btn .eco-icon {
    fill: #039BE5 !important;
}

.current-plan-btn .eco-icon {
    fill: #fff !important;
}

.selected-tier {
    background-color: #101C3D !important;
    color: #fff !important;
}

.buy-btn {
    width: 150px;
}


.eco-stack.eco-stack--v-center.eco-stack--row.eco-toggle__control.eco-mb-8 {
    margin-bottom: 0px !important;
}

.table-container {
    margin: auto;
    width: 100%;

    padding: 10px;
}
.ant-tabs-tab{
    width: 100%;
    margin: 0px 1px !important;
    text-align: center;
}
.ant-tabs-nav-list{
    width: 50%;
}
.ant-tabs-tab-btn{
    color: #1C1D21!important;
}
.selected-menu{
    background-color: #039BE5;
    border-color: #039BE5!important;
}
.long-btn{
    width: 100px;
}
.font-style-btn{
    width: 40px!important;
}
.font-style-btn .eco-button__content-wrapper{
    padding: 0 1.1rem!important;
}
.ant-popover{
    z-index: 2000000!important;
}
.show-layout-btn:focus{
    box-shadow: none!important;
    border-color: transparent!important;
}
.divider.selected{
    background-color: #E6F6FF;
}
.divider:hover{
    cursor: pointer;
}
/* PRICE PLAN   */
.price-spinner {
    margin: 20px 0 !important;
    margin-bottom: 120px !important;
    padding: 30px 50px !important;
    text-align: center !important;

    display: flex !important;
    justify-content: center !important;
}
/* PRICING PLAN CSS */


/* Text Editor */
.tox.tox-silver-sink.tox-tinymce-aux {
    position: relative;
    z-index: 1000000000
}
.custom-input, .custom-input:hover{
    background-color: #fff!important;
    border-color: transparent!important;
}
.accordian-section{
    max-height: 60vh;
    overflow-y: auto;
    padding: 5px;
}

.order-type-box{
    width: 96px;
    height: 96px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #5A5C72;
    border-radius: 4px;
}
.selected-order-type-box{
    background-color: #039BE5;
    border: none!important;
}


/* Resturant Menu Widget */

.finsish-button {
    width: 150px;
}

/* Map Widget */
/* .image-widget-component {
    margin-top: 150px !important;
} */

.video-upload-section {
    border: 2px dashed #cecece;
    border-radius: 10px;
    min-height: 150px;
    padding: 10px;
}
.eco-button.eco-button--subtle:active,
 eco-button.eco-button--subtle:focus{
    box-shadow: none;
}
.eco-button.eco-button--subtle:hover {
    background-color: transparent !important;
}
.selected-theme{
    border: 1px solid #039BE5 !important;
}
#font-picker-button .dropdown-button{
    background: #f2f2f2!important;
}

#font-picker-items .dropdown-button{
    background: #f2f2f2!important;
}

#font-picker-category .dropdown-button{
    background: #f2f2f2!important;
}

#font-picker-subcat .dropdown-button{
    background: #f2f2f2!important;
}

#font-picker .dropdown-button{
    background: #f2f2f2!important;
}

#font-picker .dropdown-button{
    background: #f2f2f2!important;
}
#font-picker-button{
    height: 3.6 rem !important;
    box-shadow: none !important;
    width: 100% !important;
}

#font-picker-items{
    height: 3.6 rem !important;
    box-shadow: none !important;
    width: 100% !important;
}

#font-picker{
    height: 3.6 rem !important;
    box-shadow: none !important;
    width: 100% !important;
}

#font-picker-subcat{
    height: 3.6 rem !important;
    box-shadow: none !important;
    width: 100% !important;
}
#font-picker-category{
    height: 3.6 rem !important;
    box-shadow: none !important;
    width: 100% !important;
}
.rw-popup-container, .sketch-picker{
    z-index: 1000000!important;
}
.one-col-layout{
    padding: 20px;
}
.one-col-layout .product-row{
    border-bottom: 1px solid #D3D3D3;
    padding: 24px 0px;
}

.two-col-layout{
    /* padding: 20px; */
    background: rgb(255, 255, 255);
}
.two-col-layout .product-row{
    border-bottom: 1px solid #D3D3D3;
    padding: 24px 0px;
}
.product-tabs .ant-tabs-tab-active .ant-tabs-tab-btn{
    color: transparent !important;
    font-size: 16px;
}
.product-tabs .ant-tabs-tab-active{
    background: transparent!important;
}
.product-tabs .ant-tabs-nav-list{
    border-color: #fff!important;
    width: 100%!important;
}
.product-tabs .ant-tabs-ink-bar{
    /* background: #FD8F0E!important; */
}
.product-tabs .ant-tabs-content-holder{
    border: transparent!important;
}
.eco-product-label{
    line-height: initial;
}


.tab-item{
    padding: 10px;
    cursor: pointer;
}
.selected-tab-item span{
    font-weight: bold;
    text-decoration: underline!important;
}
.tab-content-container{
    padding: 20px;
}
.custom-textarea {
  background: #f2f2f2 !important;
  border-color: #f2f2f2 !important;
}
.selected-layout {
  border: 1px solid #039be5;
}
.top-right-icon {
  rotate: -90deg;
}
.selected-border-radius {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: aliceblue;
  background: #e6f6ff;
  border-radius: 4px;
  padding: 6px;
}

.card-layout__two-title {
  margin: 1.5rem 0 0 2rem !important;
  display: block;
  text-align: left;
  width: 100%;
}

.card-layout__two-img {
    width: 100px;
    height: 100px;
    margin: 1.5rem 0 0 2rem !important;
}
.imagewithcontent-upload-section {
    min-height: 100px !important;
}
#content-grid .react-grid-layout {
    background-color: transparent;
    min-height: 200px;
    max-height: 600px;
    height: 600px !important
}
html,
body {
  margin: 0;
  padding: 0;
}

.advanced-container {
  display: flex;
  flex-direction: column;
}

.top-bar {
  padding: 20px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items:flex-end;
}

.top-bar-item {
  border: 1px solid #000;
  border-radius: 3px;
  height: 36px;
  padding: 8px;
  /* margin-top: 10px; */
  background: transparent;
  width: 64px;
}

.page {
  flex: 1 1 auto;
  margin: 20px;
  border: 1px solid green;
}

.dnd-drop-zone {
  flex: 0 0 auto;
  height: 30px;
  transition: 200ms all;
}

.dnd-drop-zone:nth-of-type(2n) {
  display: none;
}

.dnd-drop-zone.horizontalDrag {
  width: 20px;
  height: auto;
}

.dnd-drop-zone:not(.horizontalDrag).isLast {
  flex: 1 1 auto;
}

.dnd-drop-zone.active,
.trashDropZone.active {
  background: #00a2ff;
  transition: 100ms all;
}


.dnd-component {
  /* height: 40px; */
  padding: 10px;
  background: #aaa;
}

.dnd-column {
  border: 1px solid rgb(114, 192, 223);
  flex: 1 1 100%;
}

.dnd-columns {
  display: flex;
}

.columnContainer {
  display: flex;
}

.dnd-base {
  padding: 0.5rem 1rem;
  background-color: transparent;
  cursor: move;
}

.dnd-row {
  border: 1px solid #e7e7e7;
  padding: 0;
  max-width: 1320px;
  margin: auto;
}
.dnd-row-full-width {
  padding: 0;
  margin: auto;
}

.trashDropZone {
  position: relative;
  text-align: center;
  padding: 20px 0;
  margin: 0 auto;
  width: 200px;
  height: 60px;
  border: 1px solid rgb(233, 87, 111);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pageContainer {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  margin-bottom: 100px;
}

.layout-tablet .react-grid-layout{
    margin: 10px;
    min-height: 961px !important; 
    overflow-y: auto;
    max-height: 961px !important;
    max-width: 834px;
    margin: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; 
    outline: 35px solid #000;
    border-radius: 20px;
    overflow-x: hidden;

}
.layout-tablet{
    padding: 50px;
}
.pages__overlay {
    width: 352px;
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 162px;
    left: 65px;
    overflow-x: hidden;
    transition: all .6s ease-in;
    background-color: #ffffff;
    box-shadow: 0px 0px 0px #5a5a5a;
    border-top: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    z-index: 99991;
    overflow: scroll;

}

.preview-card {
    padding: 20px;
    border-radius: 4px;
    width: 100%;
    background: #F3F3F3;
    min-height: 150px;
}

.add-page-button {
    background-color: #70738F !important;
    border: none !important;
    border-radius: 36px !important;
    color: #ffffff !important;
    width: 150px !important;
    height: 36px !important;
    font-family: revert !important;
}

/* .add-page-button:hover {
    color: #fff !important;
    background-color: #5E81F4 !important;
} */

.eco-progress__bar {
    width: 100vw !important;
}

.header_tag {
    padding: 20px;
    border-bottom: 1px solid #D8D8D8;
}
.settings-options{
    padding: 10px;
}
.settings-options:hover{
    background-color: #E6F6FF;
    cursor: pointer;
}
.description-box{
    background-color: #f4f4f4!important;
    border-color: transparent!important;
}
.ant-tree-node-selected{
    border-color: #039BE5!important;
    color: #039BE5!important;
    background-color: #fff!important;
}
.ant-tree-node-selected .eco-icon{
    fill: #039BE5!important;
}
.ant-tree-node-content-wrapper, .ant-tree-switcher{
    padding: 10px!important;
}
.restaurant-settings__overlay {
  width: 352px;
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 162px;
  left: 65px;
  overflow-x: hidden;
  transition: all 0.6s ease-in;
  background-color: #ffffff;
  box-shadow: 0px 0px 0px #5a5a5a;
  border-top: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  z-index: 99991;
  overflow: scroll;
}

.restaurant-settings__overlay .title-bar {
  padding: 20px;
  border-bottom: 1px solid #d8d8d8;
}

.restaurant-settings-section {
  padding: 20px;
}

.restaurant-settings-section .restaurant-settings-box {
  width: 300px;
  height: 80px;
  border: 1px solid #ececf2;
  padding: 10px 30px;
  margin: 0 !important;
  cursor: pointer;
}

.restaurant-settings-section .restaurant-settings-box:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.restaurant-settings-section .restaurant-settings-box:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.restaurant-settings-card {
  padding: 20px 15px;
  padding-bottom: 20px;
}
.download-overlay .ant-space:hover{
  background: #F0F1F4;
  padding: 5px ;
}
.download-overlay .ant-space{
  padding: 5px ;
}
.download-icon{
  width: 30px;
  height: 30px;
  border-radius: 2px;
  background: #FFFFFF;
  border: 1px solid #E2E3E9;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-transfer{
  width: 100%;
}
.ant-transfer-list{
  width: 50%!important;
}
.ant-transfer-list-header-dropdown{
  display: none!important;
}
.location-list .ant-transfer-list-body .ant-checkbox{
  display: none;
}
.cms-trans-icon .ant-transfer-operation .ant-btn .anticon svg{
  position: relative;
  top: -3px;
}
.eco-mr-70 {
  padding-right: 70px !important;
}
.main-menu, .sub-menu{
    padding: 10px;
}
.main-menu:hover , .sub-menu:hover{
    cursor: pointer;
}
.main-menu-active{
    background-color: #039be5;
    color: #fff;
}
.sub-menu-active{
    background-color: #cbd7dd;
    color: #000;
}

.eco-theme-dashbord .eco-restaurant-menu-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 100%;
}
