.theme-image{
    width:250px;
    height: 150px;
    border-radius: 8px;
}
.theme-image img{
    width:100%;
    height:100%;
    object-fit:cover;
    border-radius: 8px;
}
.themes-card {
    border: 1px solid #ECECF2;
    padding: 20px;
    border-radius: 12px;
}
.theme-tabs .ant-tabs-nav-list .ant-tabs-tab-active {
    background-color: #5E81F4!important;
}
.theme-tabs .ant-tabs-nav-list .ant-tabs-tab-active .ant-tabs-tab-btn{
    color:#fff!important;
}
.theme-tabs .ant-tabs-tab{
    border-radius: 8px;
}
.theme-tabs .ant-tabs-nav-list .ant-tabs-tab {
    background-color: #EFF2FE;
}
.theme-tabs .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn{
    color:#5E81F4;
}
.theme-image-card {
    cursor: pointer;
}
.active-theme-image-card {
    border: 2px solid greenyellow;
    border-radius: 12px;
}
.element-input{
    border: transparent!important;
    background-color: transparent!important;
}
.element-input:focus{
    border: 1px solid #cecece!important;
    background-color: #fff!important;
    box-shadow: none!important;
}
.text-direction-btn{
    width: 30px!important;
}
.text-direction-btn .eco-button__content-wrapper{
    padding: 0!important;
}