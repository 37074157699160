/* colour theme settings */
 
/* Helper classes */
.mlr5 {margin-left: 5px; margin-right: 5px;}
.mt20 {margin-top: 20px;}
.mb0 {margin-bottom: 0;}
.pl0 {padding-left: 0 !important;}
.pdg-bot30 {padding-bottom: 30px;}
.min-w100 {min-width: 100px;}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

/* Google Font - Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
/* Google Font - Roboto */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

/* Body */
/* ---------------------------------------------------------------------- */
body {
  /* font-family: "Poppins","Helvetica Neue",Helvetica,Arial,sans-serif; */
  font-size: 16px;
  color: var(--on-surface-color);
  background-color: var(--surface-color);
}
/* Header */
/* ---------------------------------------------------------------------- */
.header-top {
  display: flex; 
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  background-color: var(--primary-color);
}
.login-right-wrapper {
  display: flex;
}
.header-top .contact-wrapper {
  display: flex;
  color: var(--white-color);
  font-size: 16px;
  font-weight: 500;
  margin-right: 8px;
}
a.tel-num {
  color: var(--white-color);
}
a.tel-num:hover {
  color: var(--white-color);
  text-decoration: none;
}
a.shopping-cart {
  font-size: 22px;
  height: 50px;
  color: var(--white-color);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .5rem;
}
a.shopping-cart:hover {
    transform: translateY(1px);
}
.shopping-cart .cart-count {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  font-weight: 700;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  line-height: 19px;
  text-align: center;
  color: var(--primary-color);
  background-color: var(--white-color);
  border: 2px solid;
  border-color: var(--primary-color);
}
a.login-register {
  color: var(--white-color);
  height: 50px;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: .5rem;
}
a.login-register:hover {
  transform: translateY(1px);
  text-decoration: none;
} 
.login-register .user-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: var(--primary-color);
  background-color: var(--white-color);
  display: inline-block;
  line-height: 30px;
  text-align: center;
}
.login-register .user-name {display: none;}
.login-right-wrapper .drop-down-icon-hide::after{display: none;}
.login-right-wrapper .dropdown-menu.show {
	margin-top: 3px;
	border-radius: 0;
	padding: 5px 0;
	border: 0;
  font-size: 14px;
  box-shadow: var(--box-shadow-1);
}
.login-right-wrapper .dropdown-menu.show::after {
  border-bottom: 8px solid;
  border-color: var(--white-color);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  top:-7px;
  content: "";
  display: inline-block;
  right:8px;
  position: absolute;
  z-index: 1001; 
}
.loggedin-user {
  padding: 5px 10px;
  text-align: center;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
.hr-sep {
  display: flex;
  justify-content: center;
}
.hr-sep hr {
  border-color: var(--neutral-color-6);
  margin: 5px 0;
  width: 75%;
}
.dropdown-item {
  color: var(--on-surface-color);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--primary-color);
  text-decoration: none;
  background-color: var(--white-color);
}
.dropdown-item:focus, .dropdown-item:hover {
  color: var(--on-surface-color);
  text-decoration: none;
  background-color: var(--primary-color-opacity-35);
}
.user-actions i {margin-right: 10px;}
.view-cart-wrapper {
  width: 260px;
  padding: 10px;
}
.view-cart-wrapper .cart-item {
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid;
  border-color: var(--border-color-1);
}
.view-cart-wrapper .cart-item .cart-item-image {
  margin-right: 10px;
}
.view-cart-wrapper .cart-item .cart-item-image .cart-img-thumb {
  width: 50px;
  height: auto;
}
.view-cart-wrapper .cart-item .cart-item-text {
  width: 100%;
}
.view-cart-wrapper .cart-item .cart-item-text p {
  font-size: 14px;
  margin-bottom: 0;
  color: var(--primary-color);
}
.view-cart-wrapper .cart-item .cart-item-text .cart-item-qty {
  display: flex;
  justify-content: space-between;
}
.view-cart-wrapper .cart-sub-total {
  margin-bottom: 0;
  padding-top: 10px;
  font-weight: 500;
  display: flex;
  justify-content: end;
}
.view-cart-wrapper .cart-sub-total span {
  margin-right: 10px;
}
.view-cart-wrapper button.checkout-btn {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: var(--white-color);
  background-color: var(--primary-color);
  padding: 8px 16px;
  border: 1px solid;
  border-color: var(--primary-color);
  border-radius: 3px;
  width: auto;
  height: 30px;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.view-cart-wrapper button.checkout-btn:hover {
  background-color: var(--primary-color-opacity-85);
}
.view-cart-wrapper button.view-cart-btn {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: var(--primary-color);
  background-color: var(--white-color);
  border: 1px solid;
  border-color: var(--neutral-color-5);
  padding: 8px 16px;
  border-radius: 3px;
  width: auto;
  height: 30px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.view-cart-wrapper button.view-cart-btn:hover {
  color: var(--primary-color-opacity-85);
}
.search-wrapper {
  padding: 0 20px 10px 20px;
  display: flex;
  justify-content: center;
  background-color: var(--white-color);
}
.search-wrapper .form-wrapper {
  width: 100%;
}
.search-wrapper form input {
  border-color: var(--primary-color);
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
/* mobile navigation over */
.navbar-collapse {
	position: absolute;
  background-color: var(--white-color);
  width: 100%;
  top: 44px;
  left: 0;
  padding-left: 20px;
  z-index: 100;
}
.ico-chev::after {margin-left: 10px;}
.nav-menu {
  border-radius: 0;
  box-shadow: var(--box-shadow-1);
}
.navbar-nav.m-app-nav {margin-top: 20px;}
.navbar-brand .brand-logo {height: 30px;}
.navbar-toggler {
  border: 0;
  font-size: 24px;
}
.navbar-light .navbar-toggler i.fa-bars {
  color: var(--primary-color);
}
.navbar-light .navbar-toggler i.fa-times {
  color: var(--primary-color);
}
.navbar-brand {margin-right: 40px;}
.navbar-light .navbar-nav .nav-link {
  color: var(--on-surface-color);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 8px 16px 16px 0;
}
.navbar-light .navbar-nav .nav-link:hover {
  text-decoration: none; 
  color: var(--primary-color);
  opacity: 0.85;
}
.mob-ddl {margin-right: 20px;}
.navbar-light .navbar-nav .nav-link.active {color: var(--primary-color);}
.navbar-light .navbar-nav .nav-link:active, 
.navbar-light .navbar-nav .nav-link:focus {color: var(--primary-color);}
.opt-btns {
  display: flex;
  align-items: center;
}
/* Hero Banner */
/* ---------------------------------------------------------------------- */
.hero-banner  {
  background-image: linear-gradient(
  rgba(0,0,0,0.0), 
  rgba(0,0,0, 0.35)), 
  url("../images/hero-img.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.hero-banner h1, h3 {
  color: var(--white-color);
  text-align: center;
}
.hero-banner h1 {
  font-weight: 700; 
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: 2.5rem;
  letter-spacing: 3px;
  word-spacing: 10px;
  text-shadow: -1px -1px 6px rgb(0 0 0 / 84%);
}
.hero-banner h3 {
  color: var(--white-color);
  text-align: center;
  font-weight: 600;
  text-transform: none;
}
.hero-text-wrapper {
  background-color: var(--hero-transparent-bg);
  padding: 2rem;
  margin: 2rem;
  border-radius: 8px;
}

/* Hero Other Pages */
/* ---------------------------------------------------------------------- */
.hero-banner-inner {
  background-image: linear-gradient(
  rgba(0,0,0,0.0), 
  rgba(0,0,0, 0.35)), 
  url("../images/inner-hero.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.hero-banner-inner h1 {
  color: var(--white-color);
  font-weight: 700; 
  text-transform: uppercase;
  font-size: 2.5rem;
  letter-spacing: 3px;
  word-spacing: 10px;
  text-shadow: -1px -1px 6px rgb(0 0 0 / 84%);
}

/* Site Breadcrumb */
/* ---------------------------------------------------------------------- */
.breadcrumb-container {
  padding: 20px;
}
.breadcrumb-in ul.site-breadcrumb {
	margin-bottom: 0;
	padding:0;
	list-style:none;
}
.breadcrumb-in ul.site-breadcrumb li {
	display: inline-block;
	padding-right: 20px;
	position: relative;
  font-size: 14px;
}
.breadcrumb-in ul.site-breadcrumb li a {
  color: var(--on-surface-color);
	font-size: 14px;
	text-decoration: none;
}
.breadcrumb-in ul.site-breadcrumb li a:hover {
  text-decoration: underline;
  text-decoration-thickness: 1px;
   text-underline-offset: 3px;
}
.breadcrumb-in ul.site-breadcrumb .current {
  color: var(--primary-color);
}
.breadcrumb-in ul.site-breadcrumb li::after {
  content: '>';
  font-size: 14px;
  position: absolute;
  right: 6px;
  top: 1px;
}
.breadcrumb-in ul.site-breadcrumb li:last-child::after {content: '';}

/* Product Categories */
/* ---------------------------------------------------------------------- */ 
.section {
  padding-top: 30px;
  background-color: var(--white-color);
}
.section.products {
  padding: 30px 0;
  background-color: var(--primary-color);
  background-image: linear-gradient( 
    rgba(0,0,0,0.0), 
    rgba(0,0,0, 0.35)), 
    url("../images/prd_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
h2 {
  text-align: center;
  word-spacing: 3px;
  margin-bottom: 30px;
} 
.qty-wrapper {background-color: var(--neutral-color-6); height: 30px;}
.prd-item img {width: 100%;}
.prd-item {
  margin-bottom: 30px;
  text-align: center;
}
.prd-item .prd-item-link {
  background-color: var(--white-color);
  text-decoration: none;
  display: inline-block;
  width: 100%;
}
.prd-item .prd-item-link img {margin-bottom: 4px;}
.prd-count-inner {
  display: flex;
  justify-content: space-between;
}
.prd-count-inner.sing-btn {justify-content: end;}
.prd-count-inner button {
  font-size: 12px;
  padding: 5px 10px;
  background-color: var(--neutral-color-6);
  border: 0;
  width: 40px;
  height: 30px;
}
.icon-sub, .icon-add {
  font-size: 12px;
  padding: 5px 10px;
  background-color: var(--neutral-color-6);
  border: 0;
  width: 40px;
  height: 30px;
}
.qty-no input {
  width: 50px;
  height: 30px;
  border: 1px solid;
  border-color: var(--neutral-color-5);
  background-color: var(--white-color);
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}

/* remove scroll in number input */
.qty-no input[type=number]::-webkit-inner-spin-button, 
.qty-no input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.prd-count-inner button.add-btn {
  font-size: 14px;
  font-weight: 500;
  color: var(--white-color);
  background-color: var(--primary-color);
  padding: 5px 8px;
  border: 0;
  width: auto;
  height: 30px;
}
.prd-count-inner button.add-btn:hover {
  background-color: var(--primary-color-opacity-85);
}
.section.products .prd-item .prd-item-link:hover {
  transform: translateY(1px);
}
a.prd-item-link:hover {
  text-decoration: none;
}
.prd-item .prd-item-text h5 {
  font-size: 16px;
  color: var(--on-surface-color);
  margin-bottom: 0;
  padding: 8px 0;
  text-transform: uppercase;
}
.bdr-bot {
  border-bottom: 1px solid;
  border-color: var(--neutral-color-6);
}
.header-wrapper {
  display: flex;
  flex-direction: column;
}
.section.products .header-wrapper h2 {color: var(--white-color);}
/* Product Carousel Styles */
.section .owl-theme .owl-dots .owl-dot.active span, .section .owl-theme .owl-dots .owl-dot:hover span {
  background: var(--primary-color);
}
.owl-theme .owl-nav [class*='owl-']:hover {
  background-color: var(--primary-color) !important;
}
.owl-dots {margin-top: 10px;}
.owl-nav i {font-size: 14px;}


.section.products button {
  color: var(--white-color);
  background-color: var(--primary-color);
  border-radius: 0;
  font-weight: 500;
  margin-top: 20px;
}
.section.products button:hover {
  background-color: var(--primary-color-opacity-85);
}
.section.products button span i {
  margin-left: 10px;
  font-size: 14px;
}
/* Product Range */
/* ---------------------------------------------------------------------- */ 
.section.productRange {
  background-color: var(--white-color);
}
.section.productlisting {
  background-color: var(--white-color);
}
.item-count {
  background-color: var(--neutral-color-6);
  padding: 15px;
  margin-bottom: 20px;
}
.item-count h6 {margin-bottom: 0;}

.section.productlisting {
  background-color: var(--white-color);
  padding-top: 10px;
}
.items-list a {
  padding: 8px 12px;
  color: var(--on-surface-color);
}
.items-list a:hover {
  background-color: var(--primary-color-opacity-35);
}
.items-list a.active {
  background-color: var(--primary-color);
  border: 0;
}
.mbl-prd-cat {
  margin-bottom: 20px;
}
.mbl-prd-cat .btn-cat {
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
}
.mbl-prd-cat .dropdown-menu button {
  background-color: var(--white-color);
}
.mh-at {min-height: auto !important; text-align: center !important;}

.load-more {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 20px 0;
}

.load-more a.load-more-link {
  color: var(--primary-color);
  margin-bottom: 0;
  padding: 10px 20px;
  width: 200px;
}.load-more a.load-more-link:hover {
  text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
}
/* Product Details */
/* ---------------------------------------------------------------------- */ 
.pro-img-wrapper {
  display: flex;
  flex-direction: column;
}
.pro-img-wrapper .prd-img-large img {
  border: 1px solid;
  border-color: var(--neutral-color-5);
  margin-bottom: 10px;
}
.pro-img-wrapper .prd-img-thumb {display: flex;}
.pro-img-wrapper .prd-img-thumb img {
  max-width: 100px;
  height: auto;
  margin-right: 10px;
  border: 1px solid;
  border-color: var(--neutral-color-5);
  opacity: .75;
}
.pro-img-wrapper .prd-img-thumb img.img-active {
  border-color: var(--primary-color);
  opacity: 1;
}
.prd-details-text {margin-top: 20px;}
.prd-details-text .prd-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}
.prd-details-text .prd-more-details p {margin-bottom: 10px;}
.prd-details-text .prd-price {
  font-size: 16px;
  color: var(--primary-color);
  font-weight: 600;
  margin-bottom: 20px;
}
.prd-count-inner.p-det {justify-content: flex-start;}
.prd-count-inner .select-weight {width:60%; margin-right: 20px;}
.select-qty {display: inline-block;}
.select-qty p {margin-bottom: 8px;}
.select-qty .qty-wrapper {height: 38px;}
.select-qty .qty-wrapper .a-fix {
    height: 38px;
    line-height: 38px;
}
.ft-bld {font-weight: 500;}
.qty-select-details p {font-weight: 500;}
.prd-buttons {margin-top: 30px;}
.prd-buttons .prd-add {
  border: 2px solid;
  border-color: var(--primary-color);
  color: var(--primary-color);
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  padding: 10px 0;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}
.prd-buttons .prd-add:hover {text-decoration: none;}
.prd-buttons .prd-checkout {
  border: 2px solid;
  border-color: var(--primary-color);
  background: var(--primary-color);
  color: var(--white-color);
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  padding: 10px 0;
  text-align: center;
  width: 100%;
}
.prd-buttons .prd-checkout:hover {
  text-decoration: none;
  background: var(--primary-color-opacity-85);
}
.comm-details {margin-top: 50px; margin-bottom: 20px;}

/* Accordion */
.accordion .card {
	border-radius: 0;
	border-top:0;
  background-color: var(--neutral-color-6);
	margin-bottom: 3px;
}
.accordion .card-header {
  background-color: var(--neutral-color-5);
    cursor: pointer;
    padding: 0;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
	border-radius: 0;
}
.accordion .card-header button {
	margin: 0;
	padding: 10px 15px 10px;
	font-size: 14px;
  font-weight: 600;
  color: var(--neutral-color-3);
	display: block;
}
.accordion .card-header button:focus {outline: 0; box-shadow: none; text-decoration: none;}
.accordion .card-header .btn-link:hover  {text-decoration: none;}
.accordion .card-header button:after {
	font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: '\f068';
    position: absolute;
    right: 10px;
    top: 10px;
	font-size: 12px;
}
.accordion .card-header button.collapsed:after {
	content: '\f067';
}
/* Product Checkout */
/* ---------------------------------------------------------------------- */ 
.sp-cart .search-wrapper {display: none;}
.container.checkout {padding-top: 60px; padding-bottom: 60px;}
.container.checkout .header-wrapper h2 {margin-bottom: 60px;}
.checkout .table-responsive {margin-bottom: 30px;}
.checkout .table {font-size: 14px;}
.checkout .table tr th {font-weight: 600;}
.checkout .prd-item-wrap {
  display: flex;
  width: 360px;
}
.checkout .img-small {
  width: 100px;
  height: auto;
  margin-right: 10px;
}
.checkout .prd-item-name {
  font-weight: 400;
}
.checkout .select-qty {min-width: 150px;}
.checkout .qty-wrapper {display: inline-block;}


.cart-details {display: flex; margin-bottom: 30px;}
.cart-details .align-option {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cart-details .cart-sub-total .st-item {
	min-width: 165px;
    padding: 10px;
    border: 1px solid #eee;
}
.mr10 {margin-right: 10px;}
.mr20 {margin-right: 20px;}

.cart-details .st-item {font-weight: 500;}
.cart-details .total-to-pay .st-item {font-size: 16px; font-weight: 700;}

.cart-details .cart-button-wrapper {
	margin-top: 30px;
	display:flex;
	flex-direction: column;
}
.cart-details .cart-button-wrapper a:first-child {margin-bottom: 15px;}

.cart-item-count {margin-bottom: 10px;}
.cart-badge {
  width: 30px;
    height: 30px;
    border-radius: 15px;
    line-height: 24px;
    background-color: var(--primary-color);
    color: var(--white-color);
}
.cart-buttons {
  display: flex;
  flex-direction: column;
  align-items: self-end;
  margin-top: 30px;
}
.cart-buttons a {
  text-align: center;
  padding: 10px 0;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  width: 100%;
}
.cart-buttons a.crt-btn-checkout {
  border: 2px solid;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  color: var(--white-color);
  margin-top: 15px;
}
.cart-buttons a.crt-btn-cont {
  border: 2px solid;
  border-color: var(--primary-color);
  background-color: var(--white-color);
  color: var(--primary-color);
}
.cart-buttons a.crt-btn-checkout:hover {
  background-color: var(--primary-color-opacity-85);

}

/* Best Sellers */
/* ---------------------------------------------------------------------- */ 
.prd-item {
  background-color: var(--white-color);
  border: 1px solid rgba(242, 235, 235, 0.45);
  box-shadow: var(--box-shadow-1);
  padding: 12px;   
}
.prd-item:hover {
  border: 1px solid;
  border-color: var(--primary-color);
}
.prd-item img {
  height: 230px;
}
.prd-item .prd-item-text {
  text-align: left;
  min-height: 90px;
}
.prd-item .prd-item-text h5 {
  font-weight: 400;
  text-transform: none;
}
.prd-item .prd-item-text h6 {
  font-size: 16px; 
  color: var(--primary-color);
  font-weight: 600;
  margin-bottom: 8px;
}
/* Testimonial */
/* ---------------------------------------------------------------------- */ 
.section.testimonials {
  padding: 30px 0;
  background-color: var(--primary-color);
}
.section.testimonials .header-wrapper h2 {color: var(--white-color);}
.testimonial-wrapper {
  background-color: var(--white-color);
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 20px;
  border: 1px solid rgba(242, 235, 235, 0.45);
  box-shadow: var(--box-shadow-1);
}
.testimonial-header {display: flex;}
.avt-img {margin-right: 10px;}
.avt-img-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.avt-img-name h6 {
  margin-bottom: 3px;
}
.ratings {
  font-size: 11px;
  color: var(--bright-color);
}
.ratings i {margin: 0 1px;}
.testimonial-body p {
  margin-bottom: 0;
  padding-top: 20px;
  text-align: left;
}
/* Promotions */
/* ---------------------------------------------------------------------- */ 
.section.promotions {
  padding: 0;
  margin-top: 30px;
}
.promo-wrapper {
  background-color: var(--neutral-color-6);
  height: 220px;
}
.promo-text-wrapper {
  display: flex;
  align-items: center;
  height: 220px;
}
.promo-text {
  padding: 20px;
  text-align: left;
}
.promo-text h5 {margin-bottom: 16px;}
.form-wrapper button {
  color: var(--white-color);
  background-color: var(--primary-color);
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; 
  font-weight: 500;
}
.form-wrapper button:hover {
  color: var(--white-color);
  background-color: var(--primary-color-opacity-85);
}
.form-control:focus {
  background-color: var(--white-color);
  border-color: var(--primary-color);
  outline: 0;
  box-shadow: var(--primary-box-shadow);
}
.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: var(--primary-box-shadow);
}
.email-small-text {
  margin-bottom: 0;
  margin-top: 16px;
  font-size: 12px;
}
.promo-bg-img {
    background-image: url("../images/promo-image.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 220px;
    display: none;
}
/* Our Blog */
/* ---------------------------------------------------------------------- */ 
.section.blog img {width: 100%;}
.section.blog .prd-item-text {
  text-align: left;
  padding: 10px 0;
}
.section.blog h6 {
  color: var(--neutral-color-4);
  font-size: 14px;
}
.section.blog button {
  color: var(--white-color);
  background-color: var(--primary-color);
  border-radius: 0;
  font-weight: 500;
}
.section.blog button:hover {
  background-color: var(--primary-color-opacity-85);
}
.section.blog button span i {
  margin-left: 10px;
  font-size: 14px;
}
.section.blog h4 {font-size: 20px;}
/* Footer */
/* ---------------------------------------------------------------------- */ 
.pg-footer {
  margin-top: 30px;
  padding: 30px 0 70px 0;
  background-color: var(--primary-color);
  position: relative;
}
.pg-footer .brand-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.pg-footer .brand-wrapper .logo-wrap {margin-bottom: 10px;}
.pg-footer .brand-wrapper .addr-wrap h6 {
  font-size: 13px;
  color: var(--white-color);
  text-transform: uppercase;
}
.pg-footer .brand-wrapper .addr-wrap .addr-lines span {
  color: var(--white-color);
  font-size: 12px;
  display: block;
  letter-spacing: 1px;
}
.pg-footer .social-icons a {
  font-size: 20px;
  display: inline-block;
  color: var(--white-color);
  padding: 5px 0;
  margin-right: 30px;
}
.pg-footer .social-icons a:hover {transform: translateY(1px);}
.pg-footer .social-icons a i {font-size: 24px;}
.pg-footer .pay_cards {display: flex; margin-top: 10px;}
.pg-footer .payment-icons span {
  padding: 8px;
  display: inline-block;
}
.pg-footer .payment-icons span img {
   height: 30px;
}
.pg-footer .copy {
  font-size: 10px;
  color: var(--white-color);
  text-align: left;
  letter-spacing: .5px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}
.pg-footer .copy .copy-text {padding-right: 10px; margin-bottom: 5px;}
.pg-footer .copy a {
  color: var(--white-color);
}
.pg-footer .copy .copy-sep {margin: 0 10px;}
.pg-footer .copy a:hover {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
}

.pg-footer .help {
  position: absolute;
  bottom: 15px;
  left: 15px;
}
.pg-footer .help .help-cover {
  display: inline-block;
  color: var(--white-color);
  background-color: var(--black-color);
  padding: 8px 16px;
  height: 40px;
  border-radius: 20px;
}
.pg-footer .help .help-cover > * {display: inline-block;}
.pg-footer .help .help-cover P {
  margin-bottom: 0;
  margin-left: 5px;
  font-weight: 500;
}
.pg-footer .top-up  {
  position: absolute;
  top: -20px;
  bottom: 0;
  right: 15px;
}
.pg-footer .top-up .icon-up {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: var(--black-color);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--box-shadow-1);
}
.pg-footer .top-up .icon-up i { 
  font-size: 20px;
  color: var(--white-color);
}

/* ====================================================================== */
/* Responsive Media Query */
/* ====================================================================== */
@media (min-width: 576px) {
  .search-wrapper .form-wrapper {
    width: 80%;
  }
  .pg-footer .copy {flex-direction: row;}
}
/* ----------------------------------------------------------------------- */
@media (min-width: 768px) {
  .header-top {justify-content: flex-end;}
  .mob-ddl {margin-right: 0;}
  .login-register .user-icon {margin-right: 5px;}

  .navbar-collapse {
    position: relative;
    width: auto;
    top: unset;
    left: unset;
    padding-left: 0;
    z-index: 1;
  }

  .search-wrapper {padding-bottom: 30px;}
  .search-wrapper .form-wrapper {width: 70%;}
  .login-register .user-name {display: block;}
  .section {padding-top: 60px;}
  .owl-nav {margin-top: 0 !important;}

  .pg-footer .help {bottom: 0;top: -20px;}
  .pg-footer .top-up  {top: -70px;}
  .pg-footer .top-up .icon-up {box-shadow: var(--box-shadow-2); }
  .prd-details-text {margin-top: 0;}
  .comm-details {margin-top: 50px; margin-bottom: 0;}



  .cart-buttons {flex-direction: row;}
  .cart-buttons a.crt-btn-cont {margin-right: 15px;}
  .cart-buttons a.crt-btn-checkout {margin-left: 15px;}
  
}
/* ----------------------------------------------------------------------- */
@media (min-width: 992px) {
  .navbar {padding: 30px 0;}
  .navbar-light .navbar-nav .nav-link {padding: 8px 16px; font-size: 16px;}
  .navbar-nav.m-app-nav {margin-top: 0;}
  .section.products {padding: 100px 0;}
  .section.testimonials {padding: 100px 0;}
  .section.promotions {padding: 0; margin-top: 60px;}
  .promo-bg-img {display: block;}
  .pg-footer {margin-top: 60px; padding: 60px 0 60px 0;}
  .pg-footer .brand-wrapper {flex-direction: row;}
  .pg-footer .brand-wrapper .logo-wrap {margin-bottom: 0;margin-right: 10px;}
  .section.blog .in-text-wrapper {min-height: 215px;}
  .pg-footer .pay_cards {margin-top: 0;}
  .search-wrapper .form-wrapper {width: 60%;}


  .prd-buttons .prd-add, .prd-buttons .prd-checkout {width: 47%;}
  .prd-buttons .prd-add {margin-right: 20px;}
  .checkout .table {font-size: 16px;}


  .cart-buttons a {width: 260px;}
}
/* ----------------------------------------------------------------------- */
@media (min-width: 1200px) {
  .search-wrapper .form-wrapper {
    width: 50%;
  }
  .mob-order {order: 2;}
}
/* ----------------------------------------------------------------------- */