html,
body {
  margin: 0;
  padding: 0;
}

.advanced-container {
  display: flex;
  flex-direction: column;
}

.top-bar {
  padding: 20px;
  display: flex;
  gap: 20px;
  align-items:flex-end;
}

.top-bar-item {
  border: 1px solid #000;
  border-radius: 3px;
  height: 36px;
  padding: 8px;
  /* margin-top: 10px; */
  background: transparent;
  width: 64px;
}

.page {
  flex: 1 1 auto;
  margin: 20px;
  border: 1px solid green;
}

.dnd-drop-zone {
  flex: 0 0 auto;
  height: 30px;
  transition: 200ms all;
}

.dnd-drop-zone:nth-of-type(2n) {
  display: none;
}

.dnd-drop-zone.horizontalDrag {
  width: 20px;
  height: auto;
}

.dnd-drop-zone:not(.horizontalDrag).isLast {
  flex: 1 1 auto;
}

.dnd-drop-zone.active,
.trashDropZone.active {
  background: #00a2ff;
  transition: 100ms all;
}


.dnd-component {
  /* height: 40px; */
  padding: 10px;
  background: #aaa;
}

.dnd-column {
  border: 1px solid rgb(114, 192, 223);
  flex: 1 1 100%;
}

.dnd-columns {
  display: flex;
}

.columnContainer {
  display: flex;
}

.dnd-base {
  padding: 0.5rem 1rem;
  background-color: transparent;
  cursor: move;
}

.dnd-row {
  border: 1px solid #e7e7e7;
  padding: 0;
  max-width: 1320px;
  margin: auto;
}
.dnd-row-full-width {
  padding: 0;
  margin: auto;
}

.trashDropZone {
  position: relative;
  text-align: center;
  padding: 20px 0;
  margin: 0 auto;
  width: 200px;
  height: 60px;
  border: 1px solid rgb(233, 87, 111);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pageContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 100px;
}
