.restaurant-settings__overlay {
  width: 352px;
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 162px;
  left: 65px;
  overflow-x: hidden;
  transition: all 0.6s ease-in;
  background-color: #ffffff;
  box-shadow: 0px 0px 0px #5a5a5a;
  border-top: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  z-index: 99991;
  overflow: scroll;
}

.restaurant-settings__overlay .title-bar {
  padding: 20px;
  border-bottom: 1px solid #d8d8d8;
}

.restaurant-settings-section {
  padding: 20px;
}

.restaurant-settings-section .restaurant-settings-box {
  width: 300px;
  height: 80px;
  border: 1px solid #ececf2;
  padding: 10px 30px;
  margin: 0 !important;
  cursor: pointer;
}

.restaurant-settings-section .restaurant-settings-box:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.restaurant-settings-section .restaurant-settings-box:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.restaurant-settings-card {
  padding: 20px 15px;
  padding-bottom: 20px;
}
.download-overlay .ant-space:hover{
  background: #F0F1F4;
  padding: 5px ;
}
.download-overlay .ant-space{
  padding: 5px ;
}
.download-icon{
  width: 30px;
  height: 30px;
  border-radius: 2px;
  background: #FFFFFF;
  border: 1px solid #E2E3E9;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-transfer{
  width: 100%;
}
.ant-transfer-list{
  width: 50%!important;
}
.ant-transfer-list-header-dropdown{
  display: none!important;
}
.location-list .ant-transfer-list-body .ant-checkbox{
  display: none;
}
.cms-trans-icon .ant-transfer-operation .ant-btn .anticon svg{
  position: relative;
  top: -3px;
}
.eco-mr-70 {
  padding-right: 70px !important;
}