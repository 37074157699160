.widget__overlay {
    width: 352px;
    height: 100%;  
    position: fixed;
    z-index: 1;
    top: 162px;
    left: 65px;
    overflow-x: hidden;
    transition: all .6s ease-in;
    background-color: #ffffff;
    box-shadow: 0px 0px 0px #5a5a5a;
    border-top: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    z-index: 99991;
    overflow: scroll;
}

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
.widget-div {
    padding-right: 10px;
}
.widget-div::-webkit-scrollbar {
    width: 5px;
  }
  
.widget-div::-webkit-scrollbar-track {
background: #F2F2F2;
}

.widget-div::-webkit-scrollbar-thumb {
background: #BDBDBD;
}

.widget-div::-webkit-scrollbar-thumb:hover {
background: #6E6E6E;
}


.widget__overlay__hide {
    width: 352px !important;
    height: 100% !important;
    position: fixed !important;
    z-index: 1 !important;
    top: 56px !important;
    left: 65px !important;
    overflow-x: hidden !important;
    transition: all .6s ease-in !important;
    
    box-shadow: 0px 0px 0px #5a5a5a !important;
    border-top: 1px solid !important;
    border-right: 1px solid !important;
    border-bottom: 1px solid !important;
    background-color: #000 !important;
    opacity: .5 !important;
    transition: .4s !important;
    width: 100% !important;
    z-index: 99990 !important;
}
.close-name {
    font-size: 20px;
    color: black;
}
.hideWidget__overlay {
    width: 0;
}
.widget-tabs .ant-tabs-nav-list{
    width:100%;
}
.widget-tabs .ant-tabs-content-holder{
    border: none!important;
}
.card__overlay {
    width: 100%;
    height: 150px;
}
.card__overlay:active{
    cursor: url("../../images//cursor.png"),default;
    border: 2px dashed #039BE5;
}
.card__overlay:active h5{
    color: #039BE5;
}
.card__overlay:active .eco-icon{
    fill: #039BE5;
}

/* .widget-tab{
    background: red;
} */
 
.ant-tabs-nav-list{
    border-radius: 0;
    border: 1px solid #F0F0F0;
}


.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab{
    border: none;
}

.ant-tabs-nav::before{
    border-bottom: none;
}
.search-input input{
    font-style: italic;
}

/* 

::-webkit-input-placeholder {
    font-style: italic;
 }
 :-moz-placeholder {
    font-style: italic;  
 }
 ::-moz-placeholder {
    font-style: italic;  
 }
 :-ms-input-placeholder {  
    font-style: italic; 
 } */